
const Worlds = ({ 
  worldName, 
  worldCreator, 
  worldImg, 
  worldDemo='', 
  worldType,
  worldId,
  worldLink
}) => {
  const redirectToWorld = () => {
    if (worldType === 'explore') {
      window.open(`https://mitoworld.io/world/w/${worldId}/${worldLink}`, '_blank')
    } else {
      window.location = (`https://mitoworld.io/world/${worldId}/${worldLink}`)
    }
  }
  return (
    <div className="flex flex-col basis-1/4 px-4 justify-center items-center"> 
      <div 
      className={`flex items-center rounded-full ${worldType == 'dashboard' ? '' : 'p-[16px]' } bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 transition ease-in-out duration-300 mb-[16px] overflow-hidden cursor-pointer`}
      onClick={() => {redirectToWorld(worldDemo)}}
      >
        <div className="flex items-center rounded-full overflow-hidden">
          <img
            src={worldImg}
            className="mx-auto object-cover object-center rounded-full"
            alt=""
          />
        </div>
      </div>
      <p  className={`text-center font-medium text-lg mb-[8px] max-w-[150px] break-words min-h-[56px] two-line-text`}>{worldName}</p>
      <p className="text-center font-medium text-base max-w-[150px] break-words one-line-text">by {worldCreator}</p>
    </div>
  
  );
};

export default Worlds;
