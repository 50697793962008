import React, { useEffect } from "react";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";


const SubscriptionsFailStatus = ({userData}) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Mitoworld - Payment Fail" 
  }, []);

  return (
    <Layout pageTitle="Subscriptions"  userData={userData}>
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
          <div className="bg-[#FFFFFF] flex flex-1 flex-col rounded-2xl p-8">
            <img
              src={require("../../img/assets/fail.png")}
              alt="logo"
              className="mr-auto mt-10 mb-5"
            />
            <p className="text-[#0F172A] text-lg leading-relaxed  font-medium">
              Oh no, subscription unsuccessful!
            </p>
            <p className="text-slate-500 text-lg leading-relaxed  font-medium">
              Don’t worry, it happens! Click on the button below to try again or
              return to Worlds.
            </p>
            <div className="flex flex-row  mt-10">
              <div className="mr-2">
                <Button
                  buttonTitle="Try again"
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => navigate("/worlds")}
                />
              </div>
              <Button
                buttonTitle="Back to My Worlds"
                buttonColor="[#F8FAFC]"
                titleColor="#155A9F"
                borderColor="#E2E8F0"
                hoverColor="[#E2E8F0]"
                handleClick={() => navigate("/worlds")}
              />
            </div>
            <p className="text-slate-500 text-lg leading-relaxed mt-5">
              Experiencing issues? Drop an inquiry. <a className="font-medium  text-[#155A9F]">Contact us here</a>
            </p>
          </div>
        
      </div>
    </Layout>
  );
};

export default SubscriptionsFailStatus;
