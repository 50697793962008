import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

const MoreWorld = ({ userData, jwt }) => {
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: key, direction: direction });
  };

  useEffect(() => {
    const fetchTableData = async () => {
      const formData = new FormData();
      formData.append("world_id", "0");
      formData.append("user_id", userData.userId);
      formData.append("include_json", "0");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/world-data/search`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      let data = await response.json();

      // Filter out items where is_active is 0
      data = data.filter((item) => item.view_status == 1);

      // Convert 'views' data to numbers
      data = data.map((item) => ({
        ...item,
        views: Number(item.views),
      }));

      setTableData(data);
    };
    fetchTableData();
  }, []);

  useEffect(() => {
    if (sortConfig !== null) {
      let sortedData = [...tableData];
      sortedData.forEach((item) => {
        item.created_date_time = new Date(item.created_date_time);
        item.visit_count = Number(item.visit_count);
        item.like_count = Number(item.like_count);
      });
      sortedData.sort((a, b) => {
        if (sortConfig.direction === "ascending") {
          return a[sortConfig.key] < b[sortConfig.key]
            ? -1
            : a[sortConfig.key] > b[sortConfig.key]
            ? 1
            : 0;
        } else {
          return a[sortConfig.key] > b[sortConfig.key]
            ? -1
            : a[sortConfig.key] < b[sortConfig.key]
            ? 1
            : 0;
        }
      });
      setTableData(sortedData);
    }
  }, [sortConfig]);

  return (
    <Layout
      pageTitle="World Analytics List"
      navigateBack={true}
      userData={userData}
    >
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
        <div className="bg-[#FFFFFF] flex flex-1 flex-col rounded-2xl px-10 py-6">
          <table>
            <thead>
              <tr>
                <th
                  onClick={() => sortData("world_name")}
                  style={{ textAlign: "left", paddingLeft: "50px" }}
                >
                  World Name
                  <span style={{ marginLeft: "10px" }}>
                    {sortConfig.key === "world_name" &&
                    sortConfig.direction === "ascending" ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </span>
                </th>
                <th
                  style={{ textAlign: "left" }}
                  onClick={() => sortData("like_count")}
                >
                  Likes
                  <span style={{ marginLeft: "10px" }}>
                    {sortConfig.key === "like_count" &&
                    sortConfig.direction === "ascending" ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </span>
                </th>
                <th
                  style={{ textAlign: "left" }}
                  onClick={() => sortData("visit_count")}
                >
                  Views
                  <span style={{ marginLeft: "10px" }}>
                    {sortConfig.key === "visit_count" &&
                    sortConfig.direction === "ascending" ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </span>
                </th>
                <th
                  style={{ textAlign: "left" }}
                  onClick={() => sortData("created_date_time")}
                >
                  Date Created
                  <span style={{ marginLeft: "10px" }}>
                    {sortConfig.key === "created_date_time" &&
                    sortConfig.direction === "ascending" ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData
                .filter((item) => item.is_active == 1)
                .map((item, index) => {
                  const date = new Date(item.created_date_time);
                  const formattedDate = date.toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  });

                  return (
                    <tr
                      key={index}
                      style={{ borderTop: "1px solid #CBD5E1", height: "80px" }}
                    >
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={item.world_pic || item.template_thumbnail}
                            alt={item.world_name}
                            onError={(e) => {
                              if (!e.target.src) {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://d3ae72ibwgviyf.cloudfront.net/250x250/static-assets/blueprint_static.png";
                              }
                            }}
                            style={{
                              height: "50px",
                              width: "auto",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #CBD5E1",
                              marginRight: "30px",
                              marginLeft: "50px",
                            }}
                          />
                          {item.world_name}
                        </div>
                      </td>
                      <td>{item.like_count}</td>
                      <td>{item.visit_count}</td>
                      <td>{formattedDate}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default MoreWorld;
