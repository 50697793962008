import React, { useEffect, useState, useRef } from "react";
import DropdownArrow from "../../img/DropdownArrow.svg";
import Calander from "./Calander";
import moment from "moment";

const SelectionPanel = ({ allWorlds, selectedWorlds, setSelectedWorlds, selectedLikesViews, setSelectedLikesViews, selectedDate, setSelectedDate, setCustomDates }) => {
  const MAX_NUM_WORLDS = 3;
  const worldOptions = allWorlds.map((i) => i.world_name);
  const [isWorldOpen, setIsWorldOpen] = useState(false);
  const likesViewsOptions = ["Likes", "Views"];
  const [isLikesViewsOpen, setIsLikesViewsOpen] = useState(false);
  const dateOptions = ["Past 7 days", "Past 30 days", "Custom period"];
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [fromDate, setFromDate] = useState({
    day: null,
    month: null,
    year: null
  });
  const [toDate, setToDate] = useState({
    day: null,
    month: null,
    year: null
  });
  const dropdownRef = useRef(null);

  const toggleWorldDropdown = () => {
    setIsWorldOpen(!isWorldOpen);
    setIsLikesViewsOpen(false);
    setIsDateOpen(false);
  };
  const toggleLikesViewsDropdown = () => {
    setIsLikesViewsOpen(!isLikesViewsOpen);
    setIsWorldOpen(false);
    setIsDateOpen(false);
  };
  const toggleDateDropdown = () => {
    setIsDateOpen(!isDateOpen);
    setIsWorldOpen(false);
    setIsLikesViewsOpen(false);
  };

  const toggleOptions = (option, val) => {
    // option is the option name and val represents which dropdown is being toggled: 0 -> world, 1 -> likes/views (date options handled in toggleDateOptions() below)
    const setOptionsContainer =
      val == 0 ? setSelectedWorlds : setSelectedLikesViews;

    setOptionsContainer((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter(
          (prevOption) => prevOption !== option
        );
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };

  const toggleDateOptions = (option) => {
    setSelectedDate([option]);
  };

  const disableWorldOption = (option) => {
    return (
      selectedWorlds.length >= MAX_NUM_WORLDS &&
      !selectedWorlds.includes(option)
    );
  };

  function DropdownArrowIcon() {
    return (
      <img
        src={DropdownArrow}
        alt="Dropdown Arrow Icon"
        className="rotate-180"
      />
    );
  }

  function DropdownArrowIconOpened() {
    return <img src={DropdownArrow} alt="Dropdown Arrow Icon" />;
  }

  const datesCompleted = () => {
    // check that both the 'from date' and 'to date' are completed
    return fromDate.day && fromDate.month && fromDate.year && toDate.day && toDate.month && toDate.year;
  };

  const dateString = (dateDict) => {
    // convert the dictionary of date data to a formatted string
    return moment(
      `${dateDict.month} ${dateDict.day} ${dateDict.year}`,
      "MMM DD YYYY"
    ).format("DD/MM/YYYY")
  };

  const dateValidation = () => {
    console.log(selectedDate);
    // Check if the from date is before the to date
    if (datesCompleted()) {
      const fromMonth = moment().month(fromDate.month).month();
      const toMonth = moment().month(toDate.month).month();
      const from_date = moment([fromDate.year, fromMonth, fromDate.day]);
      const to_date = moment([toDate.year, toMonth, toDate.day]);
      if (from_date.isAfter(to_date)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (datesCompleted() && dateValidation()){
      setCustomDates([fromDate, toDate])
    }
  }, [fromDate, toDate]);

  return (
    <div
      name="selectionPanel"
      className="p-10 max-w-[350px]"
      style={{ display: "flex", flexDirection: "column", width: "25%" }}
    >
      <div className="relative">
        <div className="dropdownAnchor">
          <button
            onClick={toggleWorldDropdown}
            className="flex flex-row justify-between items-center w-full pr-5"
          >
            <p className="text-lg overflow-x-hidden">
              {selectedWorlds.length == 0
                ? "Select options"
                : selectedWorlds.length == 1
                ? selectedWorlds[0]
                : "Mutiple worlds"}
            </p>{" "}
            {isWorldOpen ? <DropdownArrowIconOpened /> : <DropdownArrowIcon />}
          </button>
        </div>
        {/* Dropdown Menu for WORLD */}
        {isWorldOpen && (
          <div
            ref={dropdownRef}
            className=" dropdownMenu absolute w-full"
            style={{ maxHeight: "200px"}}
          >
            <div className="max-h-[180px] overflow-y-auto analytics-scrollbar flex flex-col w-full">
              <p className="inline-block">Select {MAX_NUM_WORLDS} world to compare</p>
              {worldOptions.map((option) => (
                <div key={option} className="font-semibold inline-block">
                  <label className="flex flex-row">
                    <input
                      disabled={disableWorldOption(option)}
                      type="checkbox"
                      checked={selectedWorlds.includes(option)}
                      onChange={() => toggleOptions(option, 0)}
                    />
                    <p
                      className={`ml-2 ${
                        disableWorldOption(option) ? "opacity-50" : null
                      }`}
                    >
                      {option}
                    </p>
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* ///////////////////////////////////////////////////////////////////////////////// */}
      </div>

      <div className="relative">
        <div className="dropdownAnchor">
          <button
            onClick={toggleLikesViewsDropdown}
            className="flex flex-row justify-between items-center w-full pr-5"
          >
            <p className="text-lg overflow-x-hidden">
              {selectedLikesViews.length == 2
                ? "Likes/Views"
                : selectedLikesViews.length == 1 ? selectedLikesViews[0] : "None"}
            </p>
            {isLikesViewsOpen ? (
              <DropdownArrowIconOpened />
            ) : (
              <DropdownArrowIcon />
            )}
          </button>
        </div>
        {/* Dropdown menu for LIKES/VIEWS */}
        {isLikesViewsOpen && (
          <div
            ref={dropdownRef}
            className="dropdownMenu flex flex-col max-h-[100%] overflow-auto absolute w-full"
            style={{ maxHeight: "200px", overflowY: "auto" }}
          >
            {likesViewsOptions.map((option) => (
              <div key={option} className="font-semibold inline-block">
                <label className="flex flex-row">
                  <input
                    // disabled={
                    //   selectedLikesViews.length == 1 &&
                    //   selectedLikesViews[0] == option
                    // }
                    type="checkbox"
                    checked={selectedLikesViews.includes(option)}
                    onChange={() => toggleOptions(option, 1)}
                  />
                  <p className={`ml-2`}>{option}</p>
                </label>
              </div>
            ))}
          </div>
        )}
        {/* ///////////////////////////////////////////////////////////////////////////////////////// */}
      </div>

      <div className="relative">
        <div className="dropdownAnchor">
          <button
            onClick={toggleDateDropdown}
            className={`flex flex-row justify-between items-center text-start w-full pr-5 ${selectedDate.includes("Custom period") && !dateValidation() ? "text-[#B91C1C]" : ""}`}
          >
            <p className="text-lg overflow-x-hidden">{selectedDate[0] == 'Custom period' && datesCompleted() ? (`${dateString(fromDate)} - ${dateString(toDate)}`) : selectedDate[0]}</p>
            {isDateOpen ? <DropdownArrowIconOpened /> : <DropdownArrowIcon />}
          </button>
        </div>
        {/* Dropdown menu for DATE */}
        {isDateOpen && (
          <div
            ref={dropdownRef}
            className="dropdownMenu flex flex-col h-auto absolute w-full"
          >
            {dateOptions.map((option) => (
              <div key={option} className="font-semibold inline-block">
                <label className="flex flex-row">
                  <input
                    type="radio"
                    checked={selectedDate.includes(option)}
                    onChange={() => {
                      toggleDateOptions(option);
                    }}
                  />
                  <p
                    className={`ml-2 ${
                      !selectedDate.includes(option) ? "opacity-50" : null
                    }`}
                  >
                    {option}
                  </p>
                </label>
              </div>
            ))}
            <Calander active={selectedDate.includes("Custom period")} fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate} />
          </div>
        )}
        {/* ///////////////////////////////////////////////////////////////////////////////////////// */}
      </div>
      <div style={{ width: "100%", marginTop: "auto" }}>
        <img
          src="https://mitoworld.io/wp-content/uploads/2024/03/legend.svg"
          alt="Legend"
        />
      </div>
    </div>
  );
};
export default SelectionPanel;
