import React, { useState,  useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import axios from "axios";


const RenewSubscription = ({userData, jwt}) => {
  const location = useLocation();
  const { worldId } = location.state;
  const [notification, setNotification] = useState("");

  const navigate = useNavigate();

  const [selectedWorld, setSelectedWorld] = useState(2);
  const [price, setPrice] = useState("$0.00");
  const [type, setType] = useState("monthly");

  useEffect(() => {
    document.title = "Mitoworld - Renew Worlds"

  }, []);

  function handlePlan(plan) {
    setSelectedWorld(plan);
    switch (plan) {
      case 2:
        setPrice("$99.00");
        break;
      default:
        setPrice("$0.00");
        break;
    }
  }

  async function upgradeWorld() {

    var  exp = Math.floor(new Date().getTime() + 6000 / 1000);

    let body = new FormData();
    
    body.append("type", 'upgrade');
    if(type == 'monthly') {
      body.append("package_type", 'monthly_mitopro');
    }else {
      body.append("package_type", 'yearly_mitopro');
    }
    body.append("world_value", worldId);
    body.append("user_id", userData.userId);
    body.append("user_email", userData.email);
    body.append("cancel_url", `${process.env.REACT_APP_DEVELOPMENT_URL}/create-fail-payment`);
    body.append("success_url", `${process.env.REACT_APP_DEVELOPMENT_URL}/create-success-payment`);

      await axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/stripe/create_stripe`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        console.log("Upgrading world", worldId);
        window.location.replace(response.data);

        // navigate("/subscription/payment_status");
      })
      .catch((err) => {
        console.error("err", err);
        setNotification("An error occured.");
      });
  }

  return (
    <Layout pageTitle="Subscription" navigateBack={true} userData={userData}>
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
        <div className="bg-[#FFFFFF] flex flex-col rounded-2xl pt-10">
          {/* Header */}
          <div className="flex justify-between mb-10 gap-10 border-b-2 pb-10 px-10">
            <div className="basis-1/2 text-left">
              <p className="text-lg mb-2">Renew Plan</p>
              <p>Access premium features with MitoPro.</p>
            </div>
          </div>

          {/* Select World */}
          <div className="flex flex-col px-10 mb-10 ">
            <div className="flex flex-row gap-x-5">
              {/* MitoLite */}
              <div
                className={`flex flex-col bg-[#155A9F] hover:bg-hover-primary-btn transition ease-in-out duration-300 w-[420px] text-white rounded-[20px] relative ${
                  selectedWorld === 1 ? "border-[#10B981]" : ""
                }`}
              >
                <div className="px-9 pt-10 pb-7 text-left">
                  {/* <p>MitoLite</p> */}
                  <div className="flex items-start">
                    <div className="">
                      <img
                        src={require("../../img/assets/mitolite_logo.png")}
                        alt="logo"
                        className="mr-auto mb-5 max-w-[150px]"
                      />
                      <p>
                        Individuals or explorers to host exhibitions, meetups,
                        and live events.{" "}
                      </p>
                    </div>
                    <img
                      src={require("../../img/assets/mitolite_icon.png")}
                      alt="logo"
                      className="max-w-[120px]"
                    />
                  </div>
                  <div className="flex flex-row mt-8">
                    <p className="text-2xl mr-1">$</p>
                    <p className="text-6xl mr-4">0</p>
                    <p className="text-sm self-end">Free forever</p>
                  </div>
                </div>

                <div className="absolute w-full h-full left-0 top-0 bg-white/[.6]"></div>

                <div className="bg-[#15508A] text-left px-9 pt-6 pb-10 rounded-[20px]">
                  <p>Includes:</p>
                  <ul className="list-disc ml-4">
                    <li>3 customisable Worlds</li>
                    <li>Create / customise a 3D avatar via Readyplayerme</li>
                    <li>
                      Create / customise a public space to host NFT art
                      exhibitions, meet-ups, and live events.
                    </li>
                    <li>Access different basic blueprints</li>
                    <li>Content storage (500mb)</li>
                    <li>Mutiplayers up to 10/World (coming soon)</li>
                  </ul>
                </div>
              </div>

              {/* MitoPro */}
              <div
                className={`flex flex-col bg-[#0F172A] w-[420px] text-white rounded-[20px] border-4 relative ${
                  selectedWorld === 2 ? "border-[#10B981]" : ""
                }`}
                onClick={() => handlePlan(2)}
              >
                <div className="px-9 pt-10 pb-7 text-left">
                  {/* <p>MitoPro</p> */}
                  <div className="flex items-start">
                    <div className="">
                      <img
                        src={require("../../img/assets/mitopro_logo.png")}
                        alt="logo"
                        className="mr-auto mb-5 w-[150px]"
                      />
                      <p>Organisations or creators to host events regularly.</p>
                    </div>
                    <img
                      src={require("../../img/assets/mitopro_icon.png")}
                      alt="logo"
                      className="max-w-[120px]"
                    />
                  </div>
                  <div className="flex flex-row mt-8">
                    <p className="text-2xl mr-1">$</p>
                    <p className="text-6xl mr-4">99</p>
                    <div className="self-end">
                      <p className="text-sm">Per World/month</p>
                      <p className="text-sm">
                        Or $83.25 per World/month when billed annually{" "}
                        <span className="bg-[#10B981] hover:bg-[#0ba371] transition ease-in-out duration-300 hover:bg-[#0c9c6c]  rounded-[4px] px-1.5">
                          -16%
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#19233A] h-full text-left px-9 pt-6 pb-10 rounded-[20px]">
                  <p>All MitoLite features, plus:</p>
                  <ul className="list-disc ml-4">
                    <li>Access different premium blueprints</li>
                    <li>Content storage (2gb)</li>
                    <li>Multiplayers up to 100/World (coming soon)</li>
                    <li>Token Gating (coming soon)</li>
                    <li>Customised domain name server (coming soon)</li>
                  </ul>
                </div>
                <div>
                  <img
                    src={require("../../img/assets/circle_tick.png")}
                    alt="logo"
                    className="max-w-[40px] absolute top-[-15px] right-[-15px]"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Price */}
          <div className="flex flex-col border-t-2 rounded-b-2xl pb-4 bg-[#E2E8F0]">
            <div className="flex flex-row px-10 py-8 justify-between">
              <div className="basis-1/3 text-left items-start flex flex-row gap-4">
                <img
                  src={require("../../img/mito_pro.png")}
                  className="scale-100"
                  alt=""
                />
                <div className="flex-col">
                  <p className="text-lg mb-2">World Type</p>
                  <p className="text-lg">MitoPro</p>
                </div>
              </div>
              <div className="basis-1/3 flex flex-col">
                <div className="flex flex-row justify-between gap-[65px]">
                  <div className="flex-col">
                    <p className="mb-2 text-left">Billing Period</p>
                    <div className="mb-3">
                      <input
                        type="radio"
                        id="monthly"
                        name="billingPeriod"
                        value="monthly"
                        onClick={() => {
                          setPrice("$99.00");
                          setType("monthly");

                        }}
                      />
                      <label htmlFor="monthly"> Monthly • $99/month</label>
                    </div>
                    <div className="mb-3">
                      <input
                        type="radio"
                        id="yearly"
                        name="billingPeriod"
                        value="yearly"
                        onClick={() => {
                          setPrice("$83.25");
                          setType("yearly");
                        }}
                      />
                      <label htmlFor="yearly"> Yearly • $83.25/month</label>
                    </div>
                  </div>
                  <div className="flex-col">
                    <p className="mb-2 text-left">Order Summary</p>
                    <p className="text-sm text-left">{price}</p>
                    <p className="text-sm text-left">x 1 month</p>
                  </div>
                </div>

                {/* <div className="bg-[#CBD5E1] px-4 -mx-4 rounded-[8px]">
                  <input
                    type="radio"
                    id="trial"
                    name="billingPeriod"
                    value="trial"
                  />
                  <label htmlFor="trial"> 1 Month Free Trial</label>
                  <br />
                  <label htmlFor="trial">
                    Enjoy 30 days of MitoPro features. No credit card required.
                    Promotion limited to first MitoPro creation.
                  </label>
                </div> */}
              </div>
              <div className="basis-1/3 text-right flex flex-row justify-end items-center">
                <div className="mr-7">
                  <p className="text-lg mb-2">Total</p>
                  <p className="text-lg mb-2">{price}</p>
                </div>
                <div>
                  <Button
                    buttonTitle="Continue"
                    titleColor="#FFFFFF"
                    buttonColor="[#10B981]"
                    hoverColor="[#0c9c6c]"
                    handleClick={() => upgradeWorld()}
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="text-right px-10 font-medium">
                By subscribing, you agree to our Terms and Conditions
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RenewSubscription;
