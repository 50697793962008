import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import * as Unicons from "@iconscout/react-unicons";
import EasyCrop from "../../components/EasyCrop";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import CreateWorldImg from "../../img/CreateWorld.svg";

const Profile = ({ userData, jwt }) => {
  const { user, getIdTokenClaims } = useAuth0();

  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState();
  const [email, setEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [defaultProfilePicture, setDefaultProfilePicture] = useState("");

  const [notification, setNotification] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [canUpdate, setCanUpdate] = useState(false);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState();
  const [showCropModal, setCropModal] = useState(false);
  const [action, setAction] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    document.title = "Mitoworld - Profile";

    getUserData();
  }, []);

  const navigate = useNavigate();

  // update profile states

  const handleNameChange = (event) => {
    setUpdateName(event.target.value);
    if (event.target.value !== username || image !== null) {
      setCanUpdate(true);
    } else {
      setCanUpdate(false);
    }

    // console.log("value is:", event.target.value);
  };

  async function getUserData() {
    const token = await getIdTokenClaims();

    let body = new FormData();
    body.append("id_token", token.__raw);

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/login`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // make name uppercase
        const str = response.data.name;
        const arr = str.split(" ");
        console.log(response);
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }

        const str2 = arr.join(" ");
        setUserId(response.data.user_id);
        setUsername(str2);
        setProfilePicture(response.data.profile_pic);
        setDefaultProfilePicture(user.picture);
        setEmail(response.data.email);
        setUpdateName(str2);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }

  async function removeProfile() {
    let body = new FormData();
    body.append("user_id", userId);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v1/staging-user-data/reset-user-profile-pic`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        getUserData();

        setNotification("Successfully reset profile picture.");

        // navigate("/", {
        //   state: {
        //     message : "Successfully reset profile picture.",
        //   },
        // });

        // setNotification('Successfully reset profile picture');
      })
      .catch((err) => {
        console.error("err", err);
        setNotification("An error occured.");
      });
  }

  async function updateProfile(id, name) {
    let body = new FormData();

    body.append("userID", id);
    body.append("profileName", name);

    if (croppedImage) {
      let response = await fetch(croppedImage);
      console.log("response", response);
      let data = await response.blob();
      console.log("data", data);
      let metadata = {
        type: "image/jpeg",
      };
      let file = new File([data], "cropped.jpg", metadata);
      body.append("uploadfile", file);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/ajax/v3/ajax_profile_pic_upload_staging.php`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        console.log("url", response.url);
        getUserData();
        setProfilePicture(response.url);
        setNotification("Update was successful.");
        window.location.reload();
      })
      .catch((err) => {
        console.error("err", err);
        setNotification("An error occured.");
      });
  }

  function changeCropModal() {
    setCropModal(!showCropModal);
  }

  function changeConfirmationModal(action) {
    setAction(action ?? "");
    setShowConfirmationModal(!showConfirmationModal);
  }

  function resetCropImage() {
    setCroppedImage(null);
    changeConfirmationModal();
  }

  const handleImageUpload = async (e) => {
    setCanUpdate(true);
    changeCropModal();
    console.log(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  function resetPassword() {
    if (user?.sub.includes("google-oauth2")) {
      setNotification("Password reset is not available for Google login.");
      return;
    }

    let body = {
      email: user?.email,
      connection: "Username-Password-Authentication",
    };

    axios
      .post(`https://auth0.mitoworld.io/dbconnections/change_password`, body, {
        headers: { "content-type": "application/json" },
      })
      .then((response) => {
        console.log(response);
        setNotification("We've just sent you an email to reset your password.");
      })
      .catch((err) => {
        console.error("err", err);
        setNotification("An error occurred.");
      });
  }

  function WorldImage() {
    return (<img src={CreateWorldImg} alt="World Image"/>)
  }

  const enterTutorial = () => {
    window.open('https://mitoworld.io/metaverse/tutorial/', '_blank');
  };

  const downloadCert = () => {
    console.log('Download Cert');
  };

  return (
    <Layout
      pageTitle="Profile"
      userData={userData}
      handleNotification={notification}
    >
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
        <div className="bg-[#FFFFFF] flex flex-1 flex-col rounded-2xl px-10 py-6">
          {/* Content */}
          <div className="flex flex-row">
            {/* Profile picture */}
            <div className="flex flex-1 flex-col border-r-2 py-9 px-9 basis-1/2">
              <p className="text-base text-left mb-4 font-medium">
                Profile Picture
              </p>
              <p className="text-base text-left text-[#334155]">
                Upload a picture that best represents you! The uploaded picture
                will only be shown on the dashboard and is not linked to your
                Mitoworld Avatar.
              </p>
              <div className="h-[336px] w-[336px] rounded-full bg-[#F1F5F9] my-4 flex self-center ">
                <div className="block text-center">
                  <input
                    type="file"
                    accept="image/*"
                    id="actual-btn"
                    name="cover"
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                  <label htmlFor="actual-btn">
                    <div className="flex items-center h-[336px] w-[336px] p-[16px] rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 overflow-hidden mx-auto">
                      <img
                        src={
                          croppedImage
                            ? croppedImage
                            : profilePicture
                            ? profilePicture
                            : defaultProfilePicture
                            ? defaultProfilePicture
                            : require("../../img/upload_button_v2.png")
                        }
                        className="w-full object-center mx-auto object-cover rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 hover:cursor-pointer"
                        alt=""
                      />
                    </div>
                    <p className="text-[#155A9F] font-medium text-center hover:text-[#10b981] transition ease-in-out duration-400 cursor-pointer mt-4">
                      {profilePicture ? "" : "Upload Picture"}
                    </p>
                  </label>

                  <p
                    className="text-[#EF4444] font-medium text-center hover:text-[#B91C1C] transition ease-in-out duration-400 cursor-pointer mt-4"
                    onClick={() => removeProfile()}
                  >
                    {profilePicture ? "Remove Picture" : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col basis-1/2">
              {/* Profile information */}
              <div className="py-9 px-16 border-b-2">
                <p className="text-lg font-medium text-left mb-4">
                  Profile Information
                </p>
                <p className="text-base text-left text-[#334155]">
                  Profile name will be display along with the World you host.
                </p>

                <form className="flex flex-col justify-start my-8">
                  <label
                    className="mb-2 text-left text-[#94A3B8]"
                    htmlFor="Email"
                  >
                    Email
                  </label>
                  <input
                    name="Email"
                    type="text"
                    className="py-4 border rounded-[62px] px-8 mb-6 text-[#94A3B8]"
                    value={email}
                    readOnly
                    disabled
                  />
                  <label className="mb-2 text-left" htmlFor="ProfileName">
                    Profile Name
                  </label>
                  <input
                    name="ProfileName"
                    type="text"
                    className="py-4 border rounded-[62px] px-8 text-[#0F172A]"
                    value={updateName}
                    onChange={handleNameChange}
                    placeholder={updateName}
                    maxLength={30}
                  />
                </form>

                <Button
                  buttonTitle="Update"
                  titleColor="#FFFFFF"
                  buttonColor={"[#155A9F]"}
                  hoverColor="hover-primary-btn"
                  buttonDisabled={!canUpdate}
                  floatLeft
                  handleClick={() =>
                    canUpdate ? updateProfile(userId, updateName, image) : ""
                  }
                />
              </div>

              {/* Update password */}
              <div className="py-9 px-16 border-b-2">
                <p className="text-lg font-medium text-left mb-4">
                  Update password
                </p>
                <p className="text-base text-left mb-6 text-[#334155]">
                  Click "Reset password" and you will receive an email with
                  instructions to proceed.
                </p>
                <Link className="flex" onClick={resetPassword}>
                  <span className="text-left text-[#155A9F] font-medium hover:text-[#10b981] transition ease-in-out duration-400 cursor-pointer">
                    Reset Password
                  </span>
                </Link>
              </div>

              {/* Learn how to create World */}
              <div className="py-9 px-16 flex flex-row justify-between">
                <div>
                  <p className="text-lg font-medium text-left mb-4">
                    Learn how to create World
                  </p>
                  <p className="text-base text-left mb-6 text-[#334155]">
                    Complete the in-game tutorial to learn the basics of World
                    creation.
                  </p>
                  <div className="flex flex-row items-center">
                  <Link className="inline-flex" onClick={enterTutorial}>
                  <span className="text-left text-[#155A9F] font-medium hover:text-[#10b981] transition ease-in-out duration-400 cursor-pointer">
                    Enter Tutorial
                  </span>
                </Link>
                <span className="h-[21px] w-[1px] border-l border-black opacity-30 mx-4"></span>
                <Link className="inline-flex" onClick={downloadCert}>
                  <span className="text-left text-[#155A9F] font-medium hover:text-[#10b981] transition ease-in-out duration-400 cursor-pointer opacity-30">
                    Download Cert
                  </span>
                </Link>
                  </div>
                  
                </div>
                <WorldImage />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <ChangeCropModal
          closeModal={() => changeCropModal()}
          image={image}
          handleCropImage={(img) => setCroppedImage(img)}
        />
      ) : null}
    </Layout>
  );
};

const ConfirmationModal = ({
  closeModal,
  action,
  publishWorld,
  unpublishWorld,
  unlinkBlueprint,
  deleteWorld,
  handleResetCropImage,
}) => {
  let copyMessage = [
    {
      action: "publish",
      title: "Confirm publish World?",
      description: "By publishing the World set to public for people to visit.",
      helperText: "You may unpublish it anytime.",
      confirmText: "Publish",
    },
    {
      action: "unpublish",
      title: "Confirm unpublish World?",
      description:
        "Unpublishing the World will remove it from all search functions and no longer be accessible by visitors. You may choose to publish your site again anytime.",
      confirmText: "Unpublish",
    },
    {
      action: "unlink",
      title: "Confirm unlink blueprint?",
      description:
        "Unlinking blueprint will detach all assets, and unpublish World.",
      helperText:
        "Uploaded assets will remain in database, reapply blueprint to access those assets.",
      confirmText: "Yes, unlink blueprint",
    },
    {
      action: "delete",
      title: "Confirm delete World?",
      description:
        "Deleting your World will erase all details, unlink blueprint and remove all assets in the World.",
      helperText:
        "For MitoPro World, the subscription details will be removed.",
      confirmText: "Yes, delete World",
    },
    {
      action: "reset_thumbnail",
      title: "Confirm reset thumbnail?",
      description:
        " Resetting thumbnail will revert back to the selected blueprint thumbnail.",
      confirmText: "Reset",
    },
  ];
  let copy = copyMessage.find((item) => item.action === action);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl flex flex-row gap-2">
          {/*content*/}
          <div className="ml-10 border-0 rounded-[24px] shadow-lg relative flex flex-col w-[437px] bg-white outline-none focus:outline-none px-6 pt-10 pb-8">
            {/*header*/}
            <div className="flex items-start justify-between rounded-t mb-2">
              <h3 className="text-lg font-medium">{copy.title}</h3>
            </div>
            {/*body*/}
            <div className="relative flex-auto">
              <p className="mb-6">{copy.description}</p>
              {copy.helperText && (
                <p className="mb-6 text-sm font-medium text-[#F59E0B]">
                  {copy.helperText}
                </p>
              )}
            </div>
            {/*buttons*/}
            <div className="flex gap-2">
              {action == "publish" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => publishWorld()}
                />
              )}
              {action == "unpublish" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => unpublishWorld()}
                />
              )}
              {action == "reset_thumbnail" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => handleResetCropImage()}
                />
              )}
              {action == "delete" || action == "unlink" ? (
                <Button
                  buttonTitle="Cancel"
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() => closeModal()}
                />
              ) : (
                <Button
                  buttonTitle="Cancel"
                  buttonColor="[#F8FAFC]"
                  borderColor="#E2E8F0"
                  titleColor="#155A9F"
                  handleClick={() => closeModal()}
                />
              )}
              {action == "unlink" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#F8FAFC]"
                  borderColor="#E2E8F0"
                  titleColor="#B91C1C"
                  handleClick={() => unlinkBlueprint()}
                />
              )}
              {action == "delete" && (
                <Button
                  buttonTitle={copy.confirmText}
                  buttonColor="[#F8FAFC]"
                  borderColor="#E2E8F0"
                  titleColor="#B91C1C"
                  handleClick={() => deleteWorld()}
                />
              )}
            </div>
          </div>
          {/* close button */}
          <div
            className="bg-[#1E1E1E] w-10 flex h-10 justify-center items-center rounded-full hover:cursor-pointer"
            onClick={() => closeModal()}
          >
            <span className="text-white">
              <Unicons.UilTimes size={30} />
            </span>
          </div>
        </div>
      </div>
      <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

const ChangeCropModal = ({ closeModal, image, handleCropImage }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-5xl flex flex-row gap-2">
          {/*content*/}
          <div className="ml-10 border-0 rounded-lg shadow-lg relative flex flex-col w-[599px] bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              {/* close button */}
              <div
                className="mr-2 justify-center items-center rounded-full hover:cursor-pointer"
                onClick={() => closeModal()}
              >
                <span className="text-black">
                  <Unicons.UilArrowLeft size={30} />
                  {/* <Unicons.UilTimes size={30} /> */}
                </span>
              </div>

              <h3 className="text-xl font-semibold">Edit Thumbnail</h3>

              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => closeModal()}
              >
                <span className="bg-transparent text-black opacity-100 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <EasyCrop
                className="cropDiv"
                image={image}
                cropImage={(img) => handleCropImage(img)}
                closeTheModal={() => closeModal()}
              />
            </div>
          </div>
          {/* close button */}
          {/* <div
            className="bg-[#1E1E1E] w-10 flex h-10 justify-center items-center rounded-full hover:cursor-pointer"
            onClick={() => closeModal()}
          >
            <span className="text-white">
              <Unicons.UilTimes size={30} />
            </span>
          </div> */}
        </div>
      </div>
      <div className="opacity-75 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default Profile;
