const Button = ({
  borderColor,
  buttonTitle,
  titleColor,
  titleSize,
  buttonColor,
  floatLeft,
  floatRight,
  floatCenter,
  handleClick,
  hoverColor,
  specialColor,
  buttonDisabled
}) => {
  const colorVariants = {
    unpublish: "bg-[#F59E0B] hover:bg-[#DD8E0A]",
  };
  return (
    <button
      disabled={buttonDisabled}
      className={`transition-all py-4 px-8 rounded-[69px] font-medium cursor-pointer
      ${
        specialColor
          ? colorVariants[specialColor]
          : `hover:bg-${hoverColor} bg-${buttonColor}`
      }
      ${floatLeft ? "float-left" : floatRight ? "float-right" : "float-none"} 
      ${borderColor ? `${borderColor} border-2` : " border-none"} `}
      onClick={handleClick}
    >
      <span
        style={{ color: titleColor ? titleColor : "white" }}
        className={`${titleSize ? titleSize : "text-base"}`}
      >
        {buttonTitle}
      </span>
    </button>
  );
};

export default Button;
