import React, { useEffect, useState, useRef } from "react";
import DropdownArrow from "../../img/DropdownArrow.svg";
import moment from "moment";

const Calander = ({ active, fromDate, setFromDate, toDate, setToDate }) => {
  const [isMonthOpen, setIsMonthOpen] = useState(false);
  const [isYearOpen, setIsYearOpen] = useState(false);
  const toggleMonthDropdown = () => {
    setIsMonthOpen(!isMonthOpen);
    setIsYearOpen(false);
  };
  const toggleYearDropdown = () => {
    setIsYearOpen(!isYearOpen);
    setIsMonthOpen(false);
  };

  function DropdownArrowIcon() {
    return (
      <img
        src={DropdownArrow}
        alt="Dropdown Arrow Icon"
        className="rotate-180 z-5"
      />
    );
  }

  function DropdownArrowIconOpened() {
    return (
      <img src={DropdownArrow} alt="Dropdown Arrow Icon" className="z-5" />
    );
  }

  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week
    },
  });

  const weekdays = moment.weekdaysShort();
  weekdays.push(weekdays.shift());

  // Set the current month and year
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [days, setDays] = useState([]);

  useEffect(() => {
    // Get the start of the month
    const startOfMonth = moment(currentMonth).startOf("month");
    
    // Get the end of the month
    const endOfMonth = moment(currentMonth).endOf("month");

  // Generate days array
  while (startOfMonth.day() != 1) {
    startOfMonth.subtract(1, "day");
  }
  let day = startOfMonth.clone();
  let temp_days = []
  while (day <= endOfMonth) {
    temp_days.push(day.clone());
    setDays(temp_days);
    day.add(1, "day");
  }
  }, [currentMonth]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [selectingFromOrTo, setSelectingFromOrTo] = useState("From");

  const handleDaySelect = (day) => {
    let new_date = { ...dateDict };
    new_date.day == day ? (new_date.day = null) : (new_date.day = day);
    setDateDict(new_date);
  };

  const handleMonthSelect = (mth) => {
    let new_date = { ...dateDict };
    new_date.month == mth ? (new_date.month = null) : (new_date.month = mth);
    setDateDict(new_date);
    setCurrentMonth(moment().month(mth));
    setIsMonthOpen(false);
  };

  const handleYearSelect = (yr) => {
    let new_date = { ...dateDict };
    new_date.year == yr ? (new_date.year = null) : (new_date.year = yr);
    setDateDict(new_date);
    setIsYearOpen(false);
  };

  const years = [];
  const currentYear = moment().year();

  for (let i = 0; i < 6; i++) {
    years.push(currentYear - i);
  }

  const handleFromClick = () => {
    if (selectingFromOrTo == "To") {
      setSelectingFromOrTo("From");
      setIsMonthOpen(false);
      setIsYearOpen(false);
      if (toDate.day == null || toDate.month == null || toDate.year == null) {
        setToDate({
          day: null,
          month: null,
          year: null,
        });
      }
    }
  };

  const handleToClick = () => {
    if (selectingFromOrTo == "From") {
      setSelectingFromOrTo("To");
      setIsMonthOpen(false);
      setIsYearOpen(false);
      if (
        fromDate.day == null ||
        fromDate.month == null ||
        fromDate.year == null
      ) {
        setFromDate({
          day: null,
          month: null,
          year: null,
        });
      }
    }
  };

  const dateValidation = () => {
    if (
      fromDate.day != null &&
      fromDate.month != null &&
      fromDate.year != null &&
      toDate.day != null &&
      toDate.month != null &&
      toDate.year != null
    ) {
      const fromMonth = moment().month(fromDate.month).month();
      const toMonth = moment().month(toDate.month).month();
      const from_date = moment([fromDate.year, fromMonth, fromDate.day]);
      const to_date = moment([toDate.year, toMonth, toDate.day]);
      if (from_date.isAfter(to_date) || to_date.isBefore(from_date) || to_date.isAfter(moment())) {
        return false;
      }
    }
    return true;
  };

  const isPrevMonth = (index, day) => {
    return index < 7 && day.date() > 7;
  };

  const [dateDict, setDateDict] = useState(fromDate);

  useEffect(() => {
    if (selectingFromOrTo == "From") {
      setDateDict(fromDate);
    } else {
      setDateDict(toDate);
    }
  }, [selectingFromOrTo, fromDate, toDate]);

  useEffect(() => {
    if (selectingFromOrTo == "From") {
      setFromDate(dateDict);
    } else {
      setToDate(dateDict);
    }
  }, [dateDict]);

  return (
    <div id="parentContainer" className="mt-2 relative">
      {!active ? (
        <div className="w-full h-full absolute bg-white opacity-50 z-10"></div>
      ) : null}
      <div
        id="fromTo"
        className="border border-[#CBD5E1] rounded-lg flex flex-row justify-between p-[5%]"
      >
        <span
          className={`${
            selectingFromOrTo == "To" ? "opacity-50" : ""
          } hover:cursor-pointer text-[0.7dvw] flex items-center justify-center`}
          onClick={() => handleFromClick()}
        >
          {fromDate.day != null &&
          fromDate.month != null &&
          fromDate.year != null
            ? moment(
                `${fromDate.month} ${fromDate.day} ${fromDate.year}`,
                "MMM DD YYYY"
              ).format("DD/MM/YYYY")
            : "From"}
        </span>{" "}
        <span>-</span>{" "}
        <span
          className={`${
            selectingFromOrTo == "From" ? "opacity-50" : ""
          } hover:cursor-pointer text-[0.7dvw] flex items-center justify-center`}
          onClick={() => {
            handleToClick();
          }}
        >
          {toDate.day != null && toDate.month != null && toDate.year != null
            ? moment(
                `${toDate.month} ${toDate.day} ${toDate.year}`,
                "MMM DD YYYY"
              ).format("DD/MM/YYYY")
            : "To"}
        </span>
      </div>
      <div
        id="calanderContainer"
        className={`mt-2 rounded-lg ${
          !dateValidation() ? "border-[1px] border-[#B91C1C]" : ""
        }`}
      >
        <div
          id="monthYear"
          className="flex flex-row justify-between border border-[#CBD5E1] px-[5%] py-[7%] rounded-t-lg"
        >
          <button
            id="month"
            className={`w-auto flex flex-row justify-start items-center hover:cursor-pointer ${
              isYearOpen ? "opacity-50" : ""
            }`}
            onClick={toggleMonthDropdown}
          >
            <p className="mr-[15%]">
              {dateDict.month != null ? dateDict.month : "Month"}
            </p>
            {isMonthOpen ? <DropdownArrowIconOpened /> : <DropdownArrowIcon />}
          </button>
          {isMonthOpen ? (
            <>
              <div className="dropdownMenu w-auto absolute max-w-[200px] border-[1px] border-[#CBD5E1] bg-white flex flex-wrap rounded-[8px] bottom-10">
                {months.map((month, index) => {
                  return (
                    <div
                      key={month}
                      className={`w-[33.33%] h-[35.92px] flex justify-center items-center hover:bg-[#E2E8F0] hover:cursor-pointer rounded-lg ${
                        month == dateDict.month ? "bg-[#E2E8F0]" : ""
                      }`}
                      onClick={() => handleMonthSelect(month)}
                    >
                      {month}
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          <button
            id="year"
            className={`w-auto flex flex-row justify-end items-center hover:cursor-pointer ${
              isMonthOpen ? "opacity-50" : ""
            }`}
            onClick={toggleYearDropdown}
          >
            <p className="mr-[15%]">
              {dateDict.year != null ? dateDict.year : "Year"}
            </p>
            {isYearOpen ? <DropdownArrowIconOpened /> : <DropdownArrowIcon />}
          </button>
          {isYearOpen ? (
            <>
              <div className="dropdownMenu w-auto absolute z-100 max-w-[200px] border-[1px] border-[#CBD5E1] bg-white flex flex-wrap rounded-[8px] right-[5%] top-[39%]">
                {years.map((year) => (
                  <div
                    key={year}
                    className={`w-[33.33%] h-[35.92px] flex justify-center items-center hover:bg-[#E2E8F0] hover:cursor-pointer rounded-lg ${
                      year == dateDict.year ? "bg-[#E2E8F0]" : ""
                    }`}
                    onClick={() => handleYearSelect(year)}
                  >
                    {year}
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </div>

        <div
          id="calander"
          className="relative border border-x-[#CBD5E1] border-b-[#CBD5E1] rounded-b-lg"
        >
          {isMonthOpen || isYearOpen ? (
            <div className="bg-black bg-opacity-50 absolute z-4 w-full h-full"></div>
          ) : null}
          <div
            id="weekdays"
            className="opacity-50 w-full flex flex-row justify-between"
          >
            {weekdays.map((day) => (
              <div
                key={day}
                className="w-[14.28%] h-[35.92px] flex justify-center items-center"
              >
                {day[0]}
              </div>
            ))}
          </div>
          <div id="days" className="flex flex-wrap">
            {days.map((day, index) => 
              day.date() < 27 && index > 27 ? null : ( // removes the first few days of the next month
                <button
                  disabled={isPrevMonth(index, day)} // disable the last few days of the previous month
                  key={index}
                  className={`w-[14.28%] font-semibold rounded-lg flex justify-center items-center h-[35.92px] ${
                    isPrevMonth(index, day) // grey out the last few days of the previous month
                      ? "opacity-50 hover:cursor-default"
                      : "hover:bg-[#E2E8F0] hover:cursor-pointer hover:text-black"
                  } ${
                    !isPrevMonth(index, day) &&
                    !dateValidation() &&
                    day.date() == dateDict.day
                      ? "text-white bg-[#B91C1C]" // make bg red if selected date is invalid
                      : day.date() == dateDict.day && !isPrevMonth(index, day) // make bg grey if day is selected
                      ? "bg-[#E2E8F0]"
                      : ""
                  }`}
                  onClick={() => handleDaySelect(day.date())}
                >
                  {day.date()}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Calander;
