import React from "react";
import * as Unicons from "@iconscout/react-unicons";
import "../../index.css";
import Layout from "../../components/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Worlds from "../../components/Worlds";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useState, useEffect } from "react";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";

const Dashboard = ({ userData, jwt }) => {
  const [worlds, setWorlds] = useState([]);
  const [background, setBackground] = useState(
    "https://mitoworld.io/wp-content/uploads/2024/03/edit-bg-jpg.webp"
  );
  const location = useLocation();
  const [state, setState] = useState(location.state || {});
  const navigate = useNavigate();


  useEffect(() => {
    if (state.message) {
      console.log("Trigger toast here", state.message);
      setState({});
      navigate(".", { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    document.title = "Mitoworld - Dashboard";
    // get trending worlds list
    async function getTrendingWorlds() {
      let body = new FormData();
      body.append("type", "trending");
      body.append("include_json", 0);
      body.append("limit", 4);

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/v1/world-data/highlighted-list`,
          body,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
        .then((response) => {
          console.log("getworlds", response.data);
          setWorlds(response.data.world_list);
        })
        .catch((err) => {
          console.error("err", err);
        });
    }

    if (jwt) {
      getTrendingWorlds();
    }
  }, [jwt]);

  return (
    <Layout
      pageTitle="Dashboard"
      userData={userData}
      handleNotification={state.message}
    >
      {/* Dashboard and profile icon */}

      <div className="flex flex-col lg:flex-row mx-14 mt-7 mb-16">
        {/* mitoworld updates */}

        <div
          className=" basis-full lg:basis-3/5 rounded-2xl font-medium p-10"
          style={{
            backgroundImage: `url('${background}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Carousel
            showArrows={false}
            onSwipeStart
            swipeable={true}
            showThumbs={false}
            showStatus={false}
            className="h-full"
            onChange={(currentIndex) => {
              setBackground(
                currentIndex === 0
                  ? "https://mitoworld.io/wp-content/uploads/2024/03/edit-bg-jpg.webp"
                  : "https://upload.wikimedia.org/wikipedia/commons/7/70/Solid_white.svg"
              );
            }}
          >
            <div
              className="text-left h-full"
              style={{
                position: "absolute",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <a
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/feed/update/urn:li:activity:7005384931933634560"
                  )
                }
                className="text-[white] hover:text-[white] text-base mt-2 group cursor-pointer"
              >
                Enter to complete the in-game tutorial here.
              </a>
              <a
                onClick={() =>
                  window.open("https://mitoworld.io/metaverse/tutorial/")
                }
                className="text-[white] text-2xl cursor-pointer"
              >
                Learn how to create a World
                <Unicons.UilArrowRight className="text-[white] inline group-hover:ml-2 transition-all" />
              </a>
            </div>

            <div className="text-left h-full">
              <p className="text-[#64748B] text-base">/ Mitoworld Updates</p>
              <p className="mt-5 mb-3 text-2xl">Get creative with Mitoworld!</p>
              <a
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/feed/update/urn:li:activity:7005384931933634560"
                  )
                }
                className="text-[#155A9F] hover:text-[#10B981] text-base mt-7 group cursor-pointer"
              >
                Watch a comparison here
                <Unicons.UilArrowRight className="inline group-hover:ml-2 transition-all" />
              </a>
            </div>
            <div className="text-left h-full">
              <p className="text-[#64748B] text-base">/ Mitoworld Updates</p>
              <p className="mt-5 mb-3 text-2xl">
                Take a journey through the immersive preview of our upcoming
                MitoPro Blueprint.
              </p>
              <a
                onClick={() =>
                  window.open(
                    "https://twitter.com/Mitoworld_io/status/1603600808821501952?s=20&t=w4hMBx9cv__yiPMyEPuS_A)"
                  )
                }
                className="text-[#155A9F] hover:text-[#10B981] text-base mt-7 group cursor-pointer"
              >
                Watch the grand unveiling of Azimuth here
                <Unicons.UilArrowRight className="inline group-hover:ml-2 transition-all" />
              </a>
            </div>
            <div className="text-left h-full">
              <p className="text-[#64748B] text-base">/ Mitoworld Updates</p>
              <p className="mt-5 mb-3 text-2xl">
                Join our Discord family and share your experiences with us.
                Don't miss out on the opportunity to participate in our events &
                giveaways!
              </p>
              <a
                onClick={() => window.open("https://discord.gg/HEkW4er92H")}
                className="text-[#155A9F] hover:text-[#10B981] text-base mt-7 group cursor-pointer"
              >
                Join our Discord now!
                <Unicons.UilArrowRight className="inline group-hover:ml-2 transition-all" />
              </a>
            </div>
          </Carousel>
        </div>
        {/* start building worlds */}
        <div className="basis-full lg:basis-2/5 bg-[#1E293B] rounded-2xl text-[#F8FAFC] p-7 ml-0 lg:ml-4">
          <p className="flex">
            {/* <Unicons.UilUser className="inline mx-1" />
            <Unicons.UilUser className="inline mx-1" />
            <Unicons.UilUser className="inline mx-1" />
            <Unicons.UilUser className="inline mx-1" />
            <Unicons.UilUser className="inline mx-1" /> */}
            <img
              src={require("../../img/assets/world_icon_1.png")}
              alt="icon"
              className="h-[15px] mr-2"
            />
            <img
              src={require("../../img/assets/world_icon_2.png")}
              alt="icon"
              className="h-[15px] mr-2"
            />
            <img
              src={require("../../img/assets/world_icon_3.png")}
              alt="icon"
              className="h-[15px] mr-2"
            />
            <img
              src={require("../../img/assets/world_icon_4.png")}
              alt="icon"
              className="h-[15px] mr-2"
            />
            <img
              src={require("../../img/assets/world_icon_5.png")}
              alt="icon"
              className="h-[15px] mr-2"
            />
          </p>
          <p className="my-7 text-2xl font-medium">Start building Worlds</p>
          {/* Create world */}
          <div className="flex">
            <Link
              to="worlds/create-worlds"
              className="bg-[#009FFD] hover:bg-[#155A9F] transition ease-in-out duration-300 rounded-full  px-7 py-3 mr-5 text-center sm:py-7"
              
            >
              Create World
            </Link>
            <div>
              <p className="text-sm">Not sure how to start?</p>
              <a
                target="_blank"
                href="https://docs.mitoworld.io/mitoworld-guide/mitoworld-guide-1/quick-start-guide"
                className="text-sm text-[#10B981] hover:text-[#0d9467]"
              >
                View Tutorial
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Highlighted worlds section */}
      <div className="mx-14">
        <div className="flex justify-between mb-14">
          <p className="font-medium text-lg">Highlighted Worlds</p>
          <Link to="/explore" className="min-w-[140px] text-left">
            <p className="text-[#155A9F] hover:text-[#10B981] font-medium group">
              Explore More{" "}
              <Unicons.UilArrowRight className="inline group-hover:ml-2 transition-all" />
            </p>
          </Link>
        </div>
        {/* cards */}
        <div className="flex flex-wrap gap-y-[48px] pb-5 ">
          {worlds.length > 0 &&
            worlds.map((w, index) => (
              <Worlds
                key={index}
                worldName={w.world_name}
                worldCreator={w.user_name}
                worldImg={
                  w.world_pic != ""
                    ? w.world_pic
                    : w.template_thumbnail != ""
                    ? w.template_thumbnail
                    : "https://d3ae72ibwgviyf.cloudfront.net/512x512/static-assets/blueprint_static.png"
                }
                worldDemo={w.link_demo}
                worldType="dashboard"
                worldId={w.world_id}
                worldLink={w.world_link}
              />
            ))}
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
