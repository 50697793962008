import React, {  useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import axios from "axios";
import Button from "../../components/Button";
import { useAuth0 } from "@auth0/auth0-react";


const CreateWorlds = ({userData, jwt}) => {
  const {
    user,
    getIdTokenClaims,
  } = useAuth0();

  useEffect(() => {
    document.title = "Mitoworld - Create World";
    getUserData();
  }, []);

  const navigate = useNavigate();

  const [worldName, setWorldName] = useState("");
  const [selectedWorld, setSelectedWorld] = useState(0);
  const [price, setPrice] = useState("$0.00");
  const [trial, setTrial] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [notification, setNotification] = useState("");
  const [type, setType] = useState("monthly");
  const [creatableLiteWorld, setCreatableLiteWorld] = useState("");

  


  function handleWorldName(event) {
    setWorldName(event.target.value);

    if (event.target.value !== "") {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }

  function handlePlan(plan) {
    setSelectedWorld(plan);
    switch (plan) {
      case 1:
        setPrice("$0.00");
        break;
      case 2:
        setPrice("$99.00");
        break;
      default:
        setPrice("$0.00");
        break;
    }
  }

  async function getUserData() {
    const token = await getIdTokenClaims();

    let body = new FormData();
    body.append("id_token", token.__raw);

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/login`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // make name uppercase
        const str = response.data.name;
        const arr = str.split(" ");

        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }

        const str2 = arr.join(" ");
        // console.log(response.data.creatable_lite_world);
        setCreatableLiteWorld(response.data.creatable_lite_world);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }


  async function createWorld() {
    var  exp = Math.floor(new Date().getTime() + 6000 / 1000);

    if(selectedWorld !== 0) {
      if (price == "$0.00" && !trial) {
        if(creatableLiteWorld > 0) {
          let body = new FormData();
        
          body.append("user_id", userData.userId);
          body.append("creator_name", userData.username);
          body.append("world_name", worldName);
          body.append("world_title", 'New world');
          body.append("world_description", 'This is a new world.');
          body.append("world_link", 'New Lite world');
          body.append("mitopro_feature", 0);
          body.append("map_json", '{"Items":{},"Theme":"","IsFirstTime":true}');
          body.append("theme_json", '{"ThemeName":"","Presets":{}}');
          body.append("exp", exp);
    
          await axios
          .post(`${process.env.REACT_APP_API_URL}/api/v1/world-data/add`,
            body,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${jwt}`,
              },
            }
          )
          .then((response) => {
            console.log(response)
            setNotification(
              response.description != "" ? response.description : response.message
            );
            navigate("/worlds", {
              state: {
                message: trial
                  ? "You have successfully created a MitoPro World."
                  : "You have sucessfully created a MitoLite World. Please proceed to apply blueprint and customise your world.",
              },
            });
          })
          .catch((err) => {
            console.error("err", err);
            setNotification("An error occured.");
          });
        } else {
          setNotification("You already reached the limitation for create lite worlds.");
        }
  
      } else if (price == "$0.00" && trial) {
      
        var today = new Date();
  
        // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        // var currentDateTime = date+' '+time;
  
        // var today = new Date();
        // var date = today.getFullYear()+'-'+(today.getMonth()+2)+'-'+today.getDate();
        // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        // var nextDateTime = date+' '+time;
  
        var worldId = '';
        let body = new FormData();
  
        body.append("type", 'create');
        body.append("package_type", 'monthly_mitopro_trial');
        body.append("world_value", worldName);
        body.append("user_id", userData.userId);
        body.append("user_email", userData.email);
        body.append("cancel_url", `${process.env.REACT_APP_DEVELOPMENT_URL}/create-fail-payment`);
        body.append("success_url", `${process.env.REACT_APP_DEVELOPMENT_URL}/create-success-payment`);
  
        await axios
        .post(`${process.env.REACT_APP_API_URL}/api/v1/stripe/create_stripe`,
          body,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
        .then((response) => {
          
          console.log(response)
          window.location.replace(response.data);
          
        })
        .catch((err) => {
          console.error("err", err);
          setNotification("An error occured.");
        });
  
      } else {
  
        let body = new FormData();
      
        body.append("type", 'create');
        if(type == 'monthly') {
          body.append("package_type", 'monthly_mitopro');
        }else {
          body.append("package_type", 'yearly_mitopro');
        }
        body.append("world_value", worldName);
        body.append("user_id", userData.userId);
        body.append("user_email", userData.email);
        body.append("cancel_url", `${process.env.REACT_APP_DEVELOPMENT_URL}/create-fail-payment`);
        body.append("success_url", `${process.env.REACT_APP_DEVELOPMENT_URL}/create-success-payment`);
  
        await axios
        .post(`${process.env.REACT_APP_API_URL}/api/v1/stripe/create_stripe`,
          body,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
        .then((response) => {
          console.log(response)
          setNotification(
            response.description != "" ? response.description : response.message
          );
          window.location.replace(response.data);
          // navigate("/subscription/payment_status", {
          //   state: {
          //     message: "You have successfully created a MitoPro World.",
          //   },
          // });
  
        })
        .catch((err) => {
          console.error("err", err);
          setNotification("An error occured.");
        });

      }
    } else {
      setNotification("Please choose a plan that supports your creation needs.");
    }

   
  }

  return (
    <Layout pageTitle="Create a World" handleNotification={notification} navigateBack={true} userData={userData}>
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
        <div className="bg-[#FFFFFF] flex flex-col rounded-2xl pt-10">
          {/* Naming World */}
          <div className="flex justify-between mb-10 gap-10 border-b-2 px-10">
            <div className="basis-1/2 text-left">
              <p className="text-lg mb-2 font-medium">Name your World</p>
              <p>
                Give your World a name that best represents it. You may edit it
                later in detail settings panel.
              </p>
            </div>
            <form className="basis-1/2 flex flex-col justify-start">
              <div className="flex flex-row justify-between">
                <label className="mb-2 text-left" htmlFor="WorldName">
                  World Name
                </label>
                <label
                  className="mb-2 text-left text-[#94A3B8]"
                  htmlFor="WorldName"
                >
                  {worldName.length}/30 Characters
                </label>
              </div>
              <input
                name="WorldName"
                type="text"
                maxLength="30" 
                className="py-4 border rounded-[16px] px-8 mb-6"
                placeholder="Eg. Brands, Exhibition, Hangout, Meta City, Activity... "
                onChange={handleWorldName}
              />
            </form>
          </div>

          {/* Select World */}
          <div className="flex flex-col px-10 mb-10 relative">
            <div className="flex flex-col text-left">
              <p className="text-lg mb-2 font-medium">Select your World</p>
              <p className="mb-6">
                Choose a plan that supports your creation needs.
              </p>
            </div>
            <div className="flex flex-row gap-x-5">
              {creatableLiteWorld > 0 ? (
              <>
              {/* MitoLite */}
              <div
                className={`flex cursor-pointer flex-col bg-[#155A9F]  transition ease-in-out duration-300 w-[420px] text-white rounded-[20px] border-4 hover:border-[#10B981] group relative ${
                  selectedWorld === 1 ? "border-[#10B981]" : ""
                }`}
                onClick={() => handlePlan(1)}
              >
                <div className="px-9 pt-10 pb-7 text-left">
                  {/* <p>MitoLite</p> */}
                  <div className="flex items-start">
                    <div className="">
                      <img
                        src={require("../../img/assets/mitolite_logo.png")}
                        alt="logo"
                        className="mr-auto mb-5 max-w-[150px]"
                      />
                      <p>
                        Individuals or explorers to host exhibitions, meetups,
                        and live events.{" "}
                      </p>
                    </div>
                    <img
                      src={require("../../img/assets/mitolite_icon.png")}
                      alt="logo"
                      className="max-w-[120px]"
                    />
                  </div>
                  <div className="flex flex-row mt-8">
                    <p className="text-2xl mr-1">$</p>
                    <p className="text-6xl mr-4">0</p>
                    <p className="text-sm self-end">Free forever</p>
                  </div>
                </div>
                <div className="bg-[#15508A] text-left px-9 pt-6 pb-10 rounded-[20px]">
                  <p>Includes:</p>
                  <ul className="list-disc ml-4">
                    <li>3 customisable Worlds</li>
                    <li>Create / customise a 3D avatar via Readyplayerme</li>
                    <li>
                      Create / customise a public space to host NFT art
                      exhibitions, meet-ups, and live events.
                    </li>
                    <li>Access different basic blueprints</li>
                    <li>Content storage (500mb)</li>
                    <li>Mutiplayers up to 10/World (coming soon)</li>
                  </ul>
                </div>
                <div>
                  <img
                    src={
                      selectedWorld === 1
                        ? require("../../img/assets/circle_tick.png")
                        : require("../../img/assets/circle.png")
                    }
                    alt="logo"
                    className="max-w-[40px] absolute top-[-15px] right-[-15px] group-hover:hidden"
                  />

                  <img
                    src={require("../../img/assets/circle_tick.png")}
                    alt="logo"
                    className="max-w-[40px] absolute top-[-15px] right-[-15px] hidden group-hover:flex"
                  />
                </div>
              </div>
              </>
              ) : (
                <>
                 {/* MitoLite */}
                  <div
                    className={`flex flex-col bg-[#155A9F] hover:bg-hover-primary-btn transition ease-in-out duration-300 w-[420px] cursor-pointer text-white rounded-[20px] relative ${
                      selectedWorld === 1 ? "border-[#10B981]" : ""
                    }`}
                  >
                    <div className="px-9 pt-10 pb-7 text-left">
                      {/* <p>MitoLite</p> */}
                      <div className="flex items-start">
                        <div className="">
                          <img
                            src={require("../../img/assets/mitolite_logo.png")}
                            alt="logo"
                            className="mr-auto mb-5 max-w-[150px]"
                          />
                          <p>
                            Individuals or explorers to host exhibitions, meetups,
                            and live events.{" "}
                          </p>
                        </div>
                        <img
                          src={require("../../img/assets/mitolite_icon.png")}
                          alt="logo"
                          className="max-w-[120px]"
                        />
                      </div>
                      <div className="flex flex-row mt-8">
                        <p className="text-2xl mr-1">$</p>
                        <p className="text-6xl mr-4">0</p>
                        <p className="text-sm self-end">Free forever</p>
                      </div>
                    </div>

                    <div className="absolute w-full h-full left-0 top-0 bg-white/[.6]"></div>

                    <div className="bg-[#15508A] text-left px-9 pt-6 pb-10 rounded-[20px]">
                      <p>Includes:</p>
                      <ul className="list-disc ml-4">
                        <li>3 customisable Worlds</li>
                        <li>Create / customise a 3D avatar via Readyplayerme</li>
                        <li>
                          Create / customise a public space to host NFT art
                          exhibitions, meet-ups, and live events.
                        </li>
                        <li>Access different basic blueprints</li>
                        <li>Content storage (500mb)</li>
                        <li>Mutiplayers up to 10/World (coming soon)</li>
                      </ul>
                    </div>
                  </div>
                </>
              )}

              {/* MitoPro */}
              <div
                className={`flex flex-col bg-[#0F172A] w-[420px] text-white rounded-[20px] border-4 relative hover:border-[#10B981] cursor-pointer group ${
                  selectedWorld === 2 ? "border-[#10B981]" : ""
                }`}
                onClick={() => handlePlan(2)}
              >
                <div className="px-9 pt-10 pb-7 text-left">
                  {/* <p>MitoPro</p> */}
                  <div className="flex items-start">
                    <div className="">
                      <img
                        src={require("../../img/assets/mitopro_logo.png")}
                        alt="logo"
                        className="mr-auto mb-5 w-[150px]"
                      />
                      <p>Organisations or creators to host events regularly.</p>
                    </div>
                    <img
                      src={require("../../img/assets/mitopro_icon.png")}
                      alt="logo"
                      className="max-w-[120px]"
                    />
                  </div>
                  <div className="flex flex-row mt-8">
                    <p className="text-2xl mr-1">$</p>
                    <p className="text-6xl mr-4">99</p>
                    <div className="self-end">
                      <p className="text-sm">Per World/month</p>
                      <p className="text-sm">
                        Or $83.25 per World/month when billed annually{" "}
                        <span className="bg-[#10B981] hover:bg-[#0ba371] transition ease-in-out duration-300 hover:bg-[#0c9c6c]  rounded-[4px] px-1.5">
                          -16%
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#19233A] h-full text-left px-9 pt-6 pb-10 rounded-[20px]">
                  <p>All MitoLite features, plus:</p>
                  <ul className="list-disc ml-4">
                    <li>Access different premium blueprints</li>
                    <li>Content storage (2gb)</li>
                    <li>Multiplayers up to 100/World (coming soon)</li>
                    <li>Token Gating (coming soon)</li>
                    <li>Customised domain name server (coming soon)</li>
                  </ul>
                </div>
                <div className="absolute bottom-[30px] right-[-20px]">
                  <img
                    src={require("../../img/assets/free_trial.png")}
                    alt="logo"
                    className="scale-100"
                  />
                </div>
                <div>
                  <img
                    src={
                      selectedWorld === 2
                        ? require("../../img/assets/circle_tick.png")
                        : require("../../img/assets/circle.png")
                    }
                    alt="logo"
                    className="max-w-[40px] absolute top-[-15px] right-[-15px]"
                  />

                  {/* <img
                    src={require("../../img/assets/circle_tick.png")}
                    alt="logo"
                    className="max-w-[40px] absolute top-[-15px] right-[-15px] hidden group-hover:flex"
                  /> */}


                </div>
              </div>
            </div>
            {isEmpty ? (
              <div className="absolute w-full h-full left-0 top-0 bg-white/[.6]"></div>
            ) : (
              ""
            )}
          </div>

          {/* Price */}
          <div className="flex flex-col border-t-2 rounded-b-2xl pb-4 bg-[#E2E8F0] relative">
            <div className="flex flex-row px-10 pt-8 justify-between pb-3">
              <div className="basis-1/3 text-left items-start flex flex-row gap-4">
                {selectedWorld === 2 ? (
                  <img
                    src={require("../../img/mito_pro.png")}
                    className="scale-100"
                    alt=""
                  />
                ) : (
                  <img
                    src={require("../../img/mito_lite.png")}
                    className="scale-100"
                    alt=""
                  />
                )}
                <div className="flex-col">
                  <p className="text-lg mb-2 font-medium">World Type</p>
                  {selectedWorld === 2 ? (
                    <p className="text-lg font-medium">MitoPro</p>
                  ) : (
                    <>
                      <p className="text-lg font-medium">MitoLite</p>
                      <p className="font-bold">You may upgrade later</p>
                    </>
                  )}
                </div>
              </div>
              <div className="basis-1/3 flex flex-col">
                <div
                  className={`flex flex-row ${
                    selectedWorld === 2 ? "justify-between" : "justify-end"
                  } gap-[65px]`}
                >
                  {selectedWorld === 2 ? (
                    <div className="flex-col">
                      <p className="mb-2 text-left">Billing Period</p>
                      {/* <p className="text-sm text-left">Monthly • $99/month</p> */}
                      <div className="mb-3">
                        <input
                          type="radio"
                          id="monthly"
                          name="billingPeriod"
                          value="monthly"
                          defaultChecked
                          onClick={() => {
                            setPrice("$99.00");
                            setTrial(false);
                            setType('monthly');
                          }}
                        />
                        <label htmlFor="monthly"> Monthly • $99/month</label>
                      </div>
                      <div className="mb-3">
                        <input
                          type="radio"
                          id="yearly"
                          name="billingPeriod"
                          value="yearly"
                          onClick={() => {
                            setPrice("$83.25");
                            setTrial(false);
                            setType('yearly');

                          }}
                        />
                        <label htmlFor="yearly"> Yearly • $83.25/month</label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex-col">
                    <p className="mb-2 text-left">Order Summary</p>
                    <p className="text-sm text-left">{price}</p>
                    <p className="text-sm text-left">x 1 month</p>
                  </div>
                </div>
                {selectedWorld === 2  &&  userData.freeTrial == 1 ? (
                  <div className="bg-[#CBD5E1] px-4 -mx-4 py-4 rounded-[8px]">
                    <input
                      type="radio"
                      id="trial"
                      name="billingPeriod"
                      value="trial"
                      onClick={() => {
                        setPrice("$0.00");
                        setTrial(true);
                        setType('trial');
                      }}
                    />
                    <label htmlFor="trial" className="font-medium"> 1 Month Free Trial</label>
                    <br />
                    <label htmlFor="trial" className="text-sm flex ml-[16px] mt-2">
                      Enjoy 30 days of MitoPro features. No credit card
                      required. Promotion limited to first MitoPro creation.
                    </label>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="basis-1/3 text-right flex flex-col justify-center items-end pl-6">
                <div className="flex flex-row justify-center mb-6">
                  <div className="mr-7">
                    <p className="text-lg mb-1">Total</p>
                    <p className="text-2xl font-medium">{price}</p>
                  </div>

                  <Button
                    buttonTitle="Create"
                    titleColor="#FFFFFF"
                    buttonColor={selectedWorld ? "[#10B981]" : "unClick-btn"}
                    hoverColor="[#0c9c6c]"
                    handleClick={() => selectedWorld ? createWorld() : ''}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end text-right pr-10 pb-3">
              <p className="font-medium text-sm">
                {selectedWorld === 2
                  ? 
                  <>
                    By subscribing, you agree to our <a className="cursor-pointer text-[#155A9F]">Terms and Conditions</a>
                  </>
                  : ""}
              </p>
            </div>
            {isEmpty ? (
              <div className="absolute w-full h-full left-0 top-0 bg-white/[.6]"></div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CreateWorlds;
