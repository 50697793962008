import React, { useEffect, useState } from "react";
import { json, Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import axios from "axios";
import Lock from "../../img/lock.svg";

const worldType = (type, subscriptionType) => {
  // switch (type, subscriptionType) {
  if (type == 0 && subscriptionType == null) {
    return (
      <p className="bg-[#009FFD] px-3.5 py-0.5 rounded-[4px] text-white text-center font-medium text-xs">
        Lite
      </p>
    );
  } else if (
    type == 1 &&
    (subscriptionType == "month" ||
      subscriptionType == "year" ||
      subscriptionType == null)
  ) {
    return (
      <p className="bg-[#155A9F] px-3.5 py-0.5 rounded-[4px] text-white text-center font-medium text-xs">
        Pro
      </p>
    );
  } else if (type == 1 && subscriptionType == "trial") {
    return (
      <p className="bg-[#155A9F] px-3.5 py-0.5 rounded-[4px] text-white text-center font-medium text-xs">
        Pro • Free Trial
      </p>
    );
  }
  // default:
  //   break;
  // }
};

const worldStatus = (status) => {
  switch (status) {
    case "0":
      return (
        <p className="bg-[#E2E8F0] px-3.5 py-0.5 rounded-[4px] text-[#94A3B8] text-center font-medium text-xs">
          Draft
        </p>
      );
    case "1":
      return (
        <p className="bg-[#10B981] hover:bg-[#0ba371] transition ease-in-out duration-300 font-medium  px-3.5 py-0.5 rounded-[4px] text-[#FFFFFF] text-center text-xs">
          Published
        </p>
      );
    case "2":
      return (
        <p className="bg-[#E2E8F0] px-3.5 py-0.5 rounded-[4px] text-[#94A3B8] font-medium text-center text-xs">
          View-only
        </p>
      );
    default:
      break;
  }
};

const MyWorlds = ({ userData, jwt }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [worlds, setWorlds] = useState([]);
  const [worldList, setWorldsList] = useState([]);

  const [state, setState] = useState(location.state || {});

  useEffect(() => {
    document.title = "Mitoworld - My Worlds";

    getAllWorlds();
  }, []);

  useEffect(() => {
    if (state.message) {
      console.log("Trigger toast here", state.message);
      setState({});
      navigate(".", { replace: true });
    }
  }, [navigate]);

  const [selected, setSelected] = useState(1);
  const handleClick = (divNum) => () => {
    setSelected(divNum);
    // all
    if (divNum === 1) {
      setWorldsList(worlds);
    }

    // Draft
    if (divNum === 2) {
      let draft = worlds.filter((world) => {
        return world.world_status == 0;
      });
      setWorldsList(draft);
    }

    // Published
    if (divNum === 3) {
      let published = worlds.filter((world) => {
        return world.world_status == 1;
      });
      setWorldsList(published);
    }

    // View
    if (divNum === 4) {
      let view = worlds.filter((world) => {
        return world.world_status == 2;
      });
      setWorldsList(view);
    }
  };

  // get all worlds: leave params empty to search all
  async function getAllWorlds() {
    let body = new FormData();

    body.append("include_json", 0);
    body.append("user_id", userData.userId);

    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/world-data/search`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        let responseFilter = response.data.filter((world) => {
          return world.is_active == 1;
        });
        setWorlds(responseFilter);
        setWorldsList(responseFilter);
        console.log(responseFilter);
      })
      .catch((err) => {
        console.error("err", err);
        setWorlds([]);
      });
  }

  /////////////////////////////////////////////////////////////////////////
  const LockIcon = () => {
    return <img src={Lock} alt="Lock Icon" className="mr-1" />;
  };

  const hasTimePassed = (datetime) => {
    if (datetime === null) {
      return false;
    } else if (new Date(datetime) > new Date()) {
      return false;
    } else {
      return true;
    }
  };
  const [gatingData, setGatingData] = useState(JSON.parse(sessionStorage.getItem("GatingData")));

  const convertDatetimeStr = (datetime) => {
    // Create a Date object from the string
    const dateObject = new Date(datetime);

    // Extract date and time components
    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString(); // Months are zero-indexed
    const year = dateObject.getFullYear();

    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    const hours12 = hours % 12 || 12; // Handle midnight (0) and noon (12)

    // Format the date and time
    const formattedDate = `${day}.${month}.${year}`;
    const formattedTime = `${hours12}:${minutes} ${ampm}`;

    return `${formattedDate}, ${formattedTime}`;
  };
  
  /////////////////////////////////////////////////////////////////////////

  return (
    <Layout
      pageTitle="My Worlds"
      handleNotification={state.message}
      userData={userData}
    >
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
        <div className="bg-[#FFFFFF] flex flex-1 flex-col rounded-2xl px-10 py-6">
          {/* Breadcrumb & Search */}
          <div className="flex justify-between mb-10 items-center">
            <div className="flex gap-3 ">
              <p className="text-[#64748B] text-base font-medium text-center">
                <a
                  onClick={handleClick(1)}
                  className={`hover:cursor-pointer ${
                    selected == "1" && "text-[#0F172A]"
                  }`}
                >
                  All Worlds
                </a>
              </p>
              <p className="text-[#0F172A] text-base font-medium text-center">
                /
              </p>
              <p className="text-[#64748B] text-base font-medium text-center">
                <a
                  onClick={handleClick(2)}
                  className={`hover:cursor-pointer ${
                    selected == "2" && "text-[#0F172A]"
                  }`}
                >
                  Draft
                </a>
              </p>
              <p className="text-[#0F172A] text-base font-medium text-center">
                /
              </p>
              <p className="text-[#64748B] text-base font-medium text-center">
                <a
                  onClick={handleClick(3)}
                  className={`hover:cursor-pointer ${
                    selected == "3" && "text-[#0F172A]"
                  }`}
                >
                  Published
                </a>
              </p>
              <p className="text-[#0F172A] text-base font-medium text-center">
                /
              </p>
              <p className="text-[#64748B] text-base font-medium text-center">
                <a
                  onClick={handleClick(4)}
                  className={`hover:cursor-pointer ${
                    selected == "4" && "text-[#0F172A]"
                  }`}
                >
                  View-only
                </a>
              </p>
            </div>
          </div>

          {/* Content */}
          <div className="flex flex-wrap gap-y-[48px] justify-start">
            <Link
              to="create-worlds"
              className="flex flex-col basis-1/4 items-center px-4"
            >
              <div className="p-[16px] min-w-[190px] max-w-[242px] rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 transition ease-in-out duration-300 overflow-hidden">
                <img
                  src={require("../../img/create_world_button.png")}
                  className="mx-auto rounded-full "
                  alt=""
                />
              </div>
              <p className="text-center font-medium text-lg mt-[16px]">
                Create a World
              </p>
            </Link>

            {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}
            {worldList.map((w, index) => (
              <Link
                key={index}
                to={`${w.world_id}`}
                state={{ worldDetails: w }}
                className="flex flex-col basis-1/4 items-center px-4"
              >
                <div
                  className={`p-[16px] min-w-[190px] max-w-[242px] rounded-full bg-[#F1F5F9] hover:bg-[#E2E8F0] transition ease-in-out duration-300 transition ease-in-out duration-300 overflow-hidden ${
                    w.world_status == "1" && "border-2 border-[#10B981]"
                  }`}
                >
                  <div className="rounded-full border-solid border-2 relative flex justify-center items-center">
                    <img
                      src={
                        w.world_pic && w.world_pic !== "null"
                          ? w.world_pic
                          : w.template_thumbnail
                          ? w.template_thumbnail
                          : w.mitopro_feature == 0 &&
                            w.subscription_type == null
                          ? require("../../img/new_world_button_v2.png")
                          : "https://d3ae72ibwgviyf.cloudfront.net/512x512/static-assets/blueprint_static.png"
                      } //to import image
                      className="mx-auto rounded-full z-5"
                      alt=""
                    />
                    {/* Date Acess Gating is enabled (only appears before start date or after end date) */}
                    {(w.world_passcode_start_date_time != null) &&
                    (!hasTimePassed(w.world_passcode_start_date_time) ||
                      hasTimePassed(w.world_passcode_end_date_time)) ? (
                      <>
                        <div className="absolute bg-[#0F172A] bg-opacity-50 h-[85%] w-[85%] rounded-full flex flex-col justify-center items-center">
                          <div className="flex flex-col justify-center items-center">
                            <p className="text-white text-sm">
                              {!hasTimePassed(
                                w.world_passcode_start_date_time
                              )
                                ? "Coming soon"
                                : "Ended"}
                            </p>
                            <p className="text-white text-sm">
                              {!hasTimePassed(
                                w.world_passcode_start_date_time
                              )
                                ? convertDatetimeStr(
                                    w.world_passcode_start_date_time
                                  )
                                : convertDatetimeStr(
                                  w.world_passcode_end_date_time
                                  )}
                            </p>
                          </div>
                          {/* Date Access Gating + Passcode Gating Activated */}
                          {w.enable_token_gating == '1' ? (
                            <div className="text-white text-sm flex flex-row absolute bottom-3">
                              <LockIcon /> Private
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) 
                    : null} 
                    {/* Only Passcode Gating is Activated */}
                    {w.enable_token_gating == '1' ? (
                    <div className="absolute bg-[#0F172A] bg-opacity-50 h-[85%] w-[85%] rounded-full flex flex-col items-center bottom-[-70%] ">
                      <div className="text-white text-sm flex flex-row absolute top-2">
                              <LockIcon /> Private
                            </div>
                    </div>)
                    : null}
                    
                  </div>
                </div>
                <p className="text-center font-medium text-lg mb-2 mt-[16px] max-w-[150px] min-h-[56px] break-words two-line-text">
                  {w.world_name}
                </p>
                <p className="text-center font-medium text-base max-w-[180px] one-line-text">
                  by {w.user_name}
                </p>
                <div className="flex gap-2 mt-2 justify-center items-center max-w-[180px]">
                  {worldType(w.mitopro_feature, w.subscription_type)}
                  {worldStatus(w.world_status)}
                </div>
              </Link>
            ))}
            {/* ///////////////////////////////////////////////////////////////////////////////////////////// */}

            {/* {worlds.length == 0 && (
              <div className="p-3 bg-yellow-100 rounded-lg block">
                Unable to retrieve worlds.
              </div>
            )} */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyWorlds;
