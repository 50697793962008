import React, { useState, useRef, useEffect } from "react";
import Layout from "../../components/Layout";
import Worlds from "../../components/Worlds";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const Explore = ({ userData, jwt }) => {
  const [selected, setSelected] = useState(1);
  const [worlds, setWorlds] = useState([]);
  // const [newWorlds, setNewWorlds] = useState([worlds]);

  const [searchKeyword, setSearchKeyword] = useState("");

  const [page, setPage] = useState(1);

  const [currentType, setCurrentType] = useState("search");

  useEffect(() => {
    document.title = "Mitoworld - Explore Worlds";
  }, []);

  useEffect(() => {
    getAllWorlds(currentType);
  }, [page]);

  useEffect(() => {
    function handleScroll() {
      if (
        Math.round(window.innerHeight + window.scrollY) >=
        document.body.offsetHeight
      ) {
        setPage((page) => page + 1);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = (divNum) => () => {
    setSelected(divNum);
    setPage(1);
    setWorlds([]);

    // all
    if (divNum === 1) {
      getAllWorlds();
      setCurrentType("all");
    }

    // trending
    if (divNum === 2) {
      getAllWorlds("trending");
      setCurrentType("trending");
    }

    // most popular
    if (divNum === 3) {
      getAllWorlds("popular");
      setCurrentType("popular");
    }

    // latest
    if (divNum === 4) {
      getAllWorlds("latest");
      setCurrentType("latest");
    }

    // visited
    if (divNum === 5) {
      getAllWorlds("visited");
      setCurrentType("visited");
    }
  };

  const handleChange = (event) => {
    setPage(1);
    setWorlds([]);
    if (event.target.value !== "" && currentType !== 'visited') {
      setSearchKeyword(event.target.value);

      const searchWorlds = setTimeout(() => {
        getAllWorlds("search", searchKeyword);
      }, 200);

      return () => clearTimeout(searchWorlds);
    } else {
      setSearchKeyword("");

      const getWorlds = setTimeout(() => {
        getAllWorlds(currentType);
      }, 200);

      return () => clearTimeout(getWorlds);
    }
  };

  const handleChangeClick = (event) => {
    setPage(1);
    setWorlds([]);
    if (searchKeyword !== "" && currentType !== 'visited') {
      const searchWorlds = setTimeout(() => {
        getAllWorlds("search", searchKeyword);
      }, 200);

      return () => clearTimeout(searchWorlds);
    } else {
      const getWorlds = setTimeout(() => {
        getAllWorlds(currentType);
      }, 200);
      
      return () => clearTimeout(getWorlds);
    }
  };

  // get all worlds: leave params empty to search all
  async function getAllWorlds(type = "search", keyword = "") {
    let body = new FormData();

    body.append("type", type);
    body.append("include_json", 0);
    body.append("limit", 12);
    body.append("pages", page);

    if (type === "search") body.append("search_value", keyword);
    if (type === "visited") body.append("matching_user_id", userData.userId);

    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/world-data/search-list`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (page === 1) {
          setWorlds(response.data.worldDetails);
        } else {
          setWorlds((worlds) => [...worlds, ...response.data.worldDetails]);
        }
      })
      .catch((err) => {
        // setWorlds([]);
        console.error("err", err);
      });
  }

  return (
    <Layout pageTitle="Explore Worlds" userData={userData}>
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
        <div className="bg-[#FFFFFF] flex flex-1 flex-col rounded-2xl px-10 py-6">
          {/* Breadcrumb & Search */}
          <div className="flex justify-between mb-10 items-center gap-4">
            <div className="flex gap-3 basis-6/12">
              <p className="text-[#94A3B8] text-base text-center font-medium">
                <a
                  onClick={handleClick(1)}
                  className={`hover:cursor-pointer ${
                    selected == "1" && "text-[#0F172A]"
                  }`}
                >
                  All
                </a>
              </p>
              <p className="text-[#94A3B8] text-base text-center">/</p>
              <p className="text-[#94A3B8] text-base text-center font-medium">
                <a
                  onClick={handleClick(2)}
                  className={`hover:cursor-pointer ${
                    selected == "2" && "text-[#0F172A]"
                  }`}
                >
                  Trending Now
                </a>
              </p>
              <p className="text-[#94A3B8] text-base text-center">/</p>
              <p className="text-[#94A3B8] text-base text-center font-medium">
                <a
                  onClick={handleClick(3)}
                  className={`hover:cursor-pointer ${
                    selected == "3" && "text-[#0F172A]"
                  }`}
                >
                  Most Popular
                </a>
              </p>
              <p className="text-[#94A3B8] text-base text-center">/</p>
              <p className="text-[#94A3B8] text-base text-center font-medium">
                <a
                  onClick={handleClick(4)}
                  className={`hover:cursor-pointer ${
                    selected == "4" && "text-[#0F172A]"
                  }`}
                >
                  Latest World
                </a>
              </p>
              <p className="text-[#94A3B8] text-base text-center">/</p>
              <p className="text-[#94A3B8] text-base text-center font-medium">
                <a
                  onClick={handleClick(5)}
                  className={`hover:cursor-pointer ${
                    selected == "5" && "text-[#0F172A]"
                  }`}
                >
                  Visited
                </a>
              </p>
            </div>

            <div className="flex basis-6/12 relative">
              <input
                id="search-bar"
                className="py-4 border rounded-[62px] w-full pl-10 pr-8 border-[#CBD5E1] bg-[#F8FAFC] font-medium fontAwesome "
                placeholder="Enter keyword or World name"
                onChange={handleChange}
              />
              <div className="absolute h-full flex items-center left-[15px]">
                <img
                  src={require("./../../img/search_icon.png")}
                  alt="logo"
                  className="mx-auto"
                />
              </div>
              <button
                onClick={handleChangeClick}
                className="bg-[#155A9F] font-medium hover:bg-hover-primary-btn transition ease-in-out duration-300 -ml-12 px-8 py-4 rounded-[62px]"
              >
                <span className="text-white">Search</span>
              </button>
            </div>
          </div>

          {/* Content */}
          <div className="flex flex-wrap gap-y-[48px]">
            {worlds.length !== 0 &&
              worlds.map((w, index) => (
                <Worlds
                  key={index}
                  worldName={w.world_name}
                  worldCreator={w.user_name}
                  worldImg={
                    w.world_pic
                      ? w.world_pic
                      : w.template_thumbnail != ""
                      ? w.template_thumbnail
                      : "https://d3ae72ibwgviyf.cloudfront.net/512x512/static-assets/blueprint_static.png"
                  }
                  worldDemo={w.link_demo}
                  worldType="explore"
                  worldId = {w.world_id}
                  worldLink={w.world_link}
                />
              ))}
            {/* {worlds.length === 0 && (
              <div className="p-3 bg-yellow-100 rounded-lg block">
                Unable to retrieve worlds.
              </div>
            )} */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Explore;
