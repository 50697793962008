import "./App.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import axios from "axios";

import Dashboard from "./screens/Dashboard";

import MyWorlds from "./screens/MyWorlds";
import CreateWorlds from "./screens/MyWorlds/create_world";
import WorldDetail from "./screens/MyWorlds/world_detail";
import UpgradeSubscription from "./screens/MyWorlds/upgrade_subscription";
import RenewSubscription from "./screens/MyWorlds/renew_subscription";
import WorldAnalytics from "./screens/WorldAnalytics";
import MoreWorld from "./screens/WorldAnalytics/MoreWorld";
import SendToken from "./screens/SendToken";
import SendString from "./screens/SendString";

import Subscriptions from "./screens/Subscriptions";
import SubscriptionsPaymentStatus from "./screens/Subscriptions/payment_status";
import SubscriptionsSuccessStatus from "./screens/Subscriptions/success";
import SubscriptionsFailStatus from "./screens/Subscriptions/fail";


import Explore from "./screens/Explore";
import Profile from "./screens/Profile";

function App() {
  const [isLoading, setIsLoading] = useState(true);


  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    handleRedirectCallback,
    getIdTokenClaims,
    getAccessTokenSilently 
  } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [userId, setUserId] = useState("");
  const [freeTrial, setFreeTrial] = useState("");
  const [creatableLiteWorld, setCreatableLiteWorld] = useState("");
  const [mitoliteWorldLimit, setMitoliteWorldLimit] = useState("");
  const [sessId, setSessId] = useState(null);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [defaultProfilePicture,   setDefaultProfilePicture] = useState("");

  const [jwt, setJwt] = useState("");

  useEffect(() => {
    async function checkUser() {
      if (localStorage.getItem('jwt')) {
        await getUserData();
        console.log("user", user);

        return;
      }

      if (isAuthenticated) {
        await getUserData();
        navigate('/')
      } else {
        login();
      }
    }

      checkUser(); // called async checkUser()
  }, [
    isAuthenticated,
    loginWithRedirect,
    userId,
    username,
    email,
    profilePicture,
    defaultProfilePicture,
    jwt,
  ]); // added loginWithRedirect

  function login() {
    const query = window.location.search;
    console.log("query", query);
    const shouldParseResult = query.includes("code=") && query.includes("state=");
    if (shouldParseResult) {
      console.log("> Should Parse Result");
      try {
        handleRedirectCallback()
          .then((resp) => {
            console.log("redirectcallback:", resp);
            
          })
          .catch((err) => {
            console.error("error:", err);
            window.location = "/";
          });
      } catch (err) {
        console.log("Error parsing redirect:", err);
      }
    } else {
      // console.log('window.location.pathname', appState);

      loginWithRedirect({
        appState: {
          returnTo: "/",
        }
      });
    }
  }

  if (isLoading) {
    // While the data is loading, render a loading message or a spinner
    return (
      <div className="loading-container">
        <div className="spinner">
          
          <img src="https://mitoworld.io/wp-content/uploads/2024/03/loading.svg" alt="Loading" className="loading-image" />
          {/*<img src="http://localhost:3000/static/media/new_world_button_v2.51b68dd74708a926342e.png" alt="Loading" className="loading-image" />*/}

          <h2 style={{textAlign: "center", fontSize: "1.2rem"}}>Loading...</h2>

        </div>
      </div>
    );  
  }
  async function getUserData() {
    console.log("Get user data");

    let token = {}
    if (isAuthenticated) {
      console.log("Authenticated");
      token = await getIdTokenClaims();}
    else{
      console.log("Not Authenticated");
      await getAccessTokenSilently().then(res => {token = res}).catch(err => {console.log(err); login()});
    }
    
    console.log("Token: ", token); // Log the token

    let body = new FormData();
    body.append("id_token", token.__raw);

    await axios
    .post(`${process.env.REACT_APP_API_URL}/api/v1/login`, body, {
      headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        setIsLoading(false);

        // make name uppercase
        const str = response.data.name;
        const arr = str.split(" ");

        for (let i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }

        const str2 = arr.join(" ");
        console.log(response.data);
        setUserId(response.data.user_id);
        setUsername(str2);
        setFreeTrial(response.data.free_trial);
        setCreatableLiteWorld(response.data.creatable_lite_world);
        setMitoliteWorldLimit(response.data.mitolite_world_limit);

        setProfilePicture(response.data.profile_pic);
        setDefaultProfilePicture(user.picture);

        setEmail(response.data.email);
        setJwt(response.data.jwt);

        localStorage.setItem('jwt', response.data.jwt);
        localStorage.setItem('userid', response.data.user_id);
        localStorage.setItem('claims', response.data.claims);

      })
      .catch((err) => {
        console.error("err", err);
        setIsLoading(false);
      });
  }

  return (
    isAuthenticated && (
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard userData={{ username, profilePicture, defaultProfilePicture }} jwt={localStorage.getItem('jwt')} />
            }
          />

          <Route
            path="worlds"
            element={<MyWorlds userData={{ userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture }}  jwt={localStorage.getItem('jwt')} />}
          />
          <Route
            path="worlds/create-worlds"
            element={<CreateWorlds userData={{mitoliteWorldLimit, creatableLiteWorld, freeTrial, email, userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture }} jwt={localStorage.getItem('jwt')} />}
          />
          <Route
            path="worlds/:id"
            element={<WorldDetail userData={{ userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture }} jwt={localStorage.getItem('jwt')}/>}
          />
          <Route
            path="worlds/upgrade"
            element={
              <UpgradeSubscription userData={{ email, userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture }} jwt={localStorage.getItem('jwt')}  />
            }
          />

          <Route
            path="worlds/renew"
            element={
              <RenewSubscription userData={{ email, userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture }} jwt={localStorage.getItem('jwt')}  />
            }
          />

          <Route
            path="subscription"
            element={<Subscriptions userData={{email, userId: localStorage.getItem('userid'),  username, profilePicture, defaultProfilePicture }} jwt={localStorage.getItem('jwt')} />}
          />
          {/* <Route
            path="subscription/payment_status"
            element={
              <SubscriptionsPaymentStatus
                userData={{ username, profilePicture, defaultProfilePicture }}
              />
            }
          /> */}

          <Route
            path="create-success-payment"
            element={
              <SubscriptionsSuccessStatus
                userData={{ userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture }} jwt={localStorage.getItem('jwt')} />
            }
          />

          <Route
            path="create-fail-payment"
            element={
              <SubscriptionsFailStatus
                userData={{ userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture }} jwt={localStorage.getItem('jwt')} />
            }
          />

          <Route
            path="explore"
            element={
              <Explore userData={{ userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture }} jwt={localStorage.getItem('jwt')} />
            }
          />
          <Route
            path="profile"
            element={
              <Profile
                userData={{ userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture, email }}
                jwt={localStorage.getItem('jwt')}
              />
            }
          />

          <Route
            path="world-analytics"
            element={
              <WorldAnalytics
                userData={{ userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture, email }}
                jwt={localStorage.getItem('jwt')}
              />
            }
          />

          <Route
            path="world-analytics/more-info"
            element={
              <MoreWorld
                userData={{ userId: localStorage.getItem('userid'), username, profilePicture, defaultProfilePicture, email }}
                jwt={localStorage.getItem('jwt')}
              />
            }
          />

          <Route
            path="send-token"
            element={
              <SendToken />
            }
          />

          <Route
            path="send-string"
            element={
              <SendString />
            }
          />

        </Routes>
      </div>
    )
  );
}

export default App;
