import React from "react";

export default function SendToken() {
    // return localStorage.getItem("sessId");
    console.log(new Response("abc123", {
        headers: { "Content-Type": "text/plain" }
    }));
    return new Response("abc123", {
        headers: { "Content-Type": "text/plain" }
    });
}
