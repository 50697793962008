import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import Subscription from "../../components/Subscriptions";
import axios from "axios";

const Subscriptions = ({userData, jwt}) => {

  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    document.title = "Mitoworld - Manage Subscriptions" 

    getAllWorlds();
  }, []);


   // get all worlds: leave params empty to search all
   async function getAllWorlds() {
    let body = new FormData();
    body.append("return_url", `${process.env.REACT_APP_DEVELOPMENT_URL}/subscription/`);
    body.append("user_id", userData.userId);
    // body.append("user_id", 10);
    body.append("include_json", 0);
    // body.append("world_id", '339');
    body.append("pro_only", 1);
    console.log("User ID", userData.userId)
    
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/world-data/search-subscription-list`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
          setSubscriptions(response.data);
          console.log("subscriptions", response.data)
      
      })
      .catch((err) => {
        console.error("err", err);
        setSubscriptions([]);
        console.log("subscriptions");

      });
   }

  return (
    <Layout pageTitle="MitoPro Subscriptions" userData={userData}>
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
        <div className="bg-[#FFFFFF] flex flex-1 flex-col rounded-2xl py-8">
          <table className="table-auto">
            <thead className="">
              <tr className="text-left border-b border-grey-300">
                <th className="font-medium text-sm min-w-[20%] pl-8 pb-5">
                  World Name
                </th>
                <th className="font-medium text-sm min-w-[14%] pb-5 pr-2">
                  Status
                </th>
                <th className="font-medium text-sm min-w-[14%] pb-5 px-2">
                  Subscription
                </th>
                <th className="font-medium text-sm min-w-[14%] pb-5 px-2">
                  Subscription Date
                </th>
                <th className="font-medium text-sm min-w-[14%] pb-5 px-2">
                  Next Billing Date
                </th>
                <th className="font-medium text-sm min-w-[24%] pb-5">Action</th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.length > 0 ? (
                subscriptions.map((s, index) => (
                  <Subscription
                    key={index}
                    world_id={s.world_id}
                    world_name={s.world_name}
                    world_pic={s.world_pic
                      ? s.world_pic
                      : s.template_thumbnail
                      ? s.template_thumbnail
                      : "https://d3ae72ibwgviyf.cloudfront.net/512x512/static-assets/blueprint_static.png"} //to import image}
                    status={s.world_status}
                    subscription_type={s.subscription_type ?? '-'}
                    subscription_start={s.subscription_start}
                    subscription_end={s.subscription_end}
                    subscription_stop={s.subscription_stop}
                    mitopro_feature={s.mitopro_feature}
                    user_id = {userData.userId}
                    user_email = {userData.email}
                    jwt = {jwt}
                    session_url = {s.session_url}
                    world_details = {s}
                    reference_id = {s.reference_id}
                    payment_method = {s.payment_method}
                  />
                ))
              ) : (
                <>
                <div className="mt-24"></div>
                <tr >
                  <td colSpan="12"  style={{ textAlign: 'center' }}>
                    <img src="https://mitoworld.io/wp-content/uploads/2024/03/globe.svg" alt="Globe" style={{ display: 'block', margin: '0 auto' }} />
                    <br></br><br></br><span style={{fontWeight: '600'}}>No MitoPro Subscription Found</span>
                  </td>
                </tr>
                  <tr>
                    <td colSpan="12" style={{ textAlign: 'center' }}>Proceed to create MitoPro World</td>
                  </tr>
                  <tr>
                    <td colSpan="12" style={{ textAlign: 'center' }}>
                      <button style={{color: "white"}} className="mt-5 bg-[#155A9F] hover:bg-[#155A9F] transition ease-in-out duration-300 rounded-full px-7 py-3">
                        <Link to="/worlds">Create Worlds</Link>
                      </button>
                    </td>
                  </tr>
                  <div className="mb-24"></div>

                </>
              )}
              </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Subscriptions;
