import React, { useEffect, useState, useRef } from "react";
import Layout from "../../components/Layout";
import ChartWindow from "./ChartWindow";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import NoDataFound from "../../img/NoDataFound.svg";

const WorldAnalytics = ({ userData, jwt }) => {
  const [selectedWorlds, setSelectedWorlds] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: key, direction: direction });
  };

  const [allWorlds, setAllWorlds] = useState([]);
  useEffect(() => {
    const fetchChartData = async () => {
      const result = await axios(
        "https://mitoworld.io/world-view-and-like-statistic/?user_id=" +
          userData.userId
      );
      setChartData(result.data);
      console.log('result: ', result);
      // // Get the range of dates in the data
      // const dates = result.data.flatMap((item) =>
      //   item.data.map(
      //     (dateTime) => new Date(dateTime).toISOString().split("T")[0]
      //   )
      // );
      // const minDate = new Date(
      //   Math.min.apply(
      //     null,
      //     dates.map((date) => new Date(date))
      //   )
      // );
      // const maxDate = new Date(
      //   Math.max.apply(
      //     null,
      //     dates.map((date) => new Date(date))
      //   )
      // );
      // Initialize the data object with an object for each date in the range
      // const data = {};
      // for (
      //   let date = new Date(minDate);
      //   date <= maxDate;
      //   date.setDate(date.getDate() + 1)
      // ) {
      //   const dateString = date.toISOString().split("T")[0];
      //   data[dateString] = { name: dateString };
      //   result.data.forEach((item) => {
      //     data[dateString][`${item.world_name} (Views)`] = 0;
      //     data[dateString][`${item.world_name} (Likes)`] = 0;
      //   });
      // }

      // Populate the data object with the counts of views and likes
      // result.data.forEach((item) => {
      //   item.data.forEach((dateTime) => {
      //     const date = new Date(dateTime).toISOString().split("T")[0];
      //     if (item.type === "views") {
      //       data[date][`${item.world_name} (Views)`] += 1;
      //     } else if (item.type === "likes") {
      //       data[date][`${item.world_name} (Likes)`] += 1;
      //     }
      //   });
      // });

      // const sortedData = Object.values(data).sort(
      //   (a, b) => new Date(a.name) - new Date(b.name)
      // );
      // setChartData(sortedData);
      // console.log('fetch chart data', sortedData);
    };

    const featchAllWorlds = async () => {
      const formData = new FormData();
      formData.append("world_id", "0");
      formData.append("user_id", userData.userId);
      formData.append("include_json", "0");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/world-data/search`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );
      let data = await response.json();
      
      // Convert 'views' data to numbers
      data = data.map((item) => ({
        ...item,
        views: Number(item.views),
      }));
      data = data.filter((item) => item.view_status == "1");
      setAllWorlds(data);      
    };

    fetchChartData();
    featchAllWorlds();
  }, []);

  useEffect(() => {
    if (sortConfig !== null) {
      let sortedData = [...tableData];
      sortedData.forEach((item) => {item.created_date_time = new Date(item.created_date_time); item.visit_count = Number(item.visit_count); item.like_count = Number(item.like_count);});
      sortedData.sort((a, b) => {
        if (sortConfig.direction === "ascending") {
          return a[sortConfig.key] < b[sortConfig.key]
            ? -1
            : a[sortConfig.key] > b[sortConfig.key]
            ? 1
            : 0;
        } else {
          return a[sortConfig.key] > b[sortConfig.key]
            ? -1
            : a[sortConfig.key] < b[sortConfig.key]
            ? 1
            : 0;
        }
      });
      setTableData(sortedData);
    }
  }, [sortConfig]);

  useEffect(() => {
      setTableData(allWorlds.filter(world => selectedWorlds.includes(world.world_name)));
  }, [selectedWorlds]);

  function NoDataFoundIcon() {
    return <img src={NoDataFound} alt="No Data Found" />;
  }

  return (
    <Layout pageTitle="World Analytics" userData={userData}>
      <div className="flex flex-col mx-14 mt-7 pb-10 space-x-4">
        <div className="bg-[#FFFFFF] flex flex-1 flex-col rounded-2xl px-5 py-6">
          {/* Breadcrumb & Search */}
          {/* Content */}
          <div
            className="rounded-2xl mb-6 flex justify-center"
            style={{ backgroundColor: "#f8fafc" }}
          >
            {/* ///////////////////////////////////////////////////////////////////////////////// */}
            {
              // If user has world data then show chart else show 'no data found'
              chartData.length > 0 ? (
                <ChartWindow allWorlds={allWorlds} chartData={chartData} selectedWorlds={selectedWorlds} setSelectedWorlds={setSelectedWorlds} />
              ) : (
                <div className="flex flex-col justify-content items-center w-[15vw] py-20">
                  <NoDataFoundIcon />
                  <p className="font-semibold text-center pt-8">
                    No Data Found
                  </p>
                  <p className="text-[#334155] text-center pb-8">
                    Proceed to create a world
                  </p>
                  <button className="bg-[#155A9F] text-white rounded-full py-[15px] w-[70%]">
                    Create World
                  </button>
                </div>
              )
            }

            {/* ///////////////////////////////////////////////////////////////////////////////// */}
          </div>
          <table>
            <thead>
              <tr>
                <th
                  onClick={() => sortData("world_name")}
                  style={{ textAlign: "left", paddingLeft: "50px" }}
                >
                  World Name
                  <span style={{ marginLeft: "10px" }}>
                    {sortConfig.key === "world_name" &&
                    sortConfig.direction === "ascending" ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </span>
                </th>
                <th
                  style={{ textAlign: "left" }}
                  onClick={() => sortData("like_count")}
                >
                  Likes
                  <span style={{ marginLeft: "10px" }}>
                    {sortConfig.key === "like_count" &&
                    sortConfig.direction === "ascending" ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </span>
                </th>
                <th
                  style={{ textAlign: "left" }}
                  onClick={() => sortData("visit_count")}
                >
                  Views
                  <span style={{ marginLeft: "10px" }}>
                    {sortConfig.key === "visit_count" &&
                    sortConfig.direction === "ascending" ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </span>
                </th>
                <th
                  style={{ textAlign: "left" }}
                  onClick={() => sortData("created_date_time")}
                >
                  Date Created
                  <span style={{ marginLeft: "10px" }}>
                    {sortConfig.key === "created_date_time" &&
                    sortConfig.direction === "ascending" ? (
                      <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronDown} />
                    )}
                  </span>
                </th>
                <th>
                  <a
                    href="/world-analytics/more-info"
                    style={{ color: "#155A9F", textDecoration: "none" }}
                  >
                    View More
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#155A9F", marginLeft: "10px" }}
                    />
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.length == 0 ? (<tr style={{ borderTop: "1px solid #CBD5E1", height: "80px" }}><td style={{paddingLeft: '50px'}}>-</td><td>-</td><td>-</td><td>-</td><td></td></tr>) : 
              (tableData.map((item, index) => {
                const date = new Date(item.created_date_time);
                const formattedDate = date.toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                });

                return (
                  <tr
                    key={index}
                    style={{ borderTop: "1px solid #CBD5E1", height: "80px" }}
                  >
                    <td>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            item.world_pic ||
                            "https://d3ae72ibwgviyf.cloudfront.net/250x250/static-assets/blueprint_static.png"
                          }
                          alt={item.world_name}
                          onError={(e) => {
                            if (!e.target.src) {
                              e.target.onerror = null;
                              e.target.src =
                                "https://d3ae72ibwgviyf.cloudfront.net/250x250/static-assets/blueprint_static.png";
                            }
                          }}
                          style={{
                            height: "50px",
                            width: "auto",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid #CBD5E1",
                            marginRight: "30px",
                            marginLeft: "50px",
                          }}
                        />
                        {item.world_name}
                      </div>
                    </td>
                    <td>{item.like_count}</td>
                    <td>{item.visit_count}</td>
                    <td>{formattedDate}</td>
                    <td></td>
                  </tr>
                );
              }))}
            </tbody>
              
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default WorldAnalytics;
