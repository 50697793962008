import React, { useEffect } from "react";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";


const SubscriptionsSuccessStatus = ({userData}) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Mitoworld - Payment Success"
  }, []);

  return (
    <Layout pageTitle="Subscriptions"  userData={userData}>
      <div className="flex flex-col h-80 mx-14 mt-7 pb-10 space-x-4 successDiv">
        
          <div className="bg-[#FFFFFF] flex flex-1 flex-col rounded-2xl p-8">
            <img
              src={require("../../img/assets/success.png")}
              alt="logo"
              className="mr-auto mt-10 mb-5"
            />
            <p className="text-[#0F172A] text-lg font-medium leading-relaxed">
              Subscription Success!
            </p>
            <p className="text-slate-500 text-lg leading-relaxed font-medium">
              Congratulations, we are excited to see your creation. Let's go!
            </p>
            <div className="flex flex-row mt-10">
              <div className="mr-2">
                <Button
                  buttonTitle="Back to My Worlds"
                  buttonColor="[#155A9F]"
                  hoverColor="hover-primary-btn"
                  handleClick={() =>
                    navigate("/worlds", {
                      state: {
                        message:
                          "You have successfully created a MitoPro World. Please proceed to apply blueprint and customise your world.",
                      },
                    })
                  }
                />
              </div>
              <Button
                buttonTitle="View Subscription Details"
                buttonColor="[#F8FAFC]"
                titleColor="#155A9F"
                borderColor="#E2E8F0"
                handleClick={() => navigate("/subscription")}
              />
            </div>
            <p className="text-slate-500 text-lg leading-relaxed mt-5">
              You may always view and manage your subscription under MitoPro
              Subscriptions.
            </p>
          </div>
       
      </div>
    </Layout>
  );
};

export default SubscriptionsSuccessStatus;
