import React, { useEffect, useState, useRef } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import SelectionPanel from "./SelectionPanel";
import moment from "moment";

const ChartWindow = ({
  allWorlds,
  chartData,
  selectedWorlds,
  setSelectedWorlds,
}) => {
  const [selectedLikesViews, setSelectedLikesViews] = useState([
    "Likes",
    "Views",
  ]);
  const [selectedDate, setSelectedDate] = useState(["Past 30 days"]);
  const [customDates, setCustomDates] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [filteredData, setFilteredData] = useState();
  const colors = ["#FF595E", "#FFCA3A", "#8AC926", "#1982C4", "#6A4C93"];
  const [worldColors, setWorldColors] = useState({});
  const [chartingData, setChartingData] = useState([]); 
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    let temp = {};
    for (let i in selectedWorlds) {
      temp[selectedWorlds[i]] = colors[i];
    }
    setWorldColors(temp);
  }, [selectedWorlds]);

  // generates a dictionary of dates within the date range (each key's value is set to 0)
  const generateDateRange = (startDate, endDate) => {
    let dateRange = {};
    let count_date = startDate;
    while (count_date.isBefore(endDate)) {
      dateRange[count_date.format("YYYY-MM-DD")] = 0;
      count_date.add(1, "days");
    }
    dateRange[endDate.format("YYYY-MM-DD")] = 0;
    return dateRange;
  };

  // counts the number of likes/views on each date within the date range
  const generateRecords = (data, dateRange) => { 
    for (let i in data) {
      let records = { ...dateRange };
      let entry = data[i];
      for (let j in entry.data) {
        let date = entry.data[j];
        records[date]++;
      }
      entry.records = records;
    }
    return data;
  };

  // assumes that chartData is an array that contains data of every worlds that a user owns
  useEffect(() => {
    let data = JSON.parse(JSON.stringify(chartData)); // API currently only returns 5 preset worlds

    // handle Likes/Views selection
    if (
      selectedLikesViews.includes("Likes") &&
      selectedLikesViews.includes("Views")
    ) {
      // leave data as is
    } else if (selectedLikesViews.includes("Likes")) {
      data = data.filter((entry) => entry.type == "likes");
    } else if (selectedLikesViews.includes("Views")) {
      data = data.filter((entry) => entry.type == "views");
    } else {
      data = [];
    }

    // handle Time period selection
    let dateRange = {};
    let startDate = null;
    let endDate = null;
    if (!selectedDate.includes("Custom period")) { // if 7 days or 30 days period is selected
      const d = selectedDate[0] == "Past 7 days" ? 7 : 30;
      startDate = moment().subtract(d-1, "days").startOf("day");
      endDate = moment().endOf("day");
      for (let i in data) {
        let entry = data[i];
        for (let j = entry.data.length - 1; j >= 0; j--) { // iterate backwards to check most recent dates first
          let datestring = entry.data[j];
          if (moment(datestring).isBefore(startDate)) { // if encounter a date that is before the start date
            entry.data = entry.data.slice(j + 1, entry.data.length); // remove all dates before start date
            entry.data = entry.data.map(
              (datestring) => datestring.split(" ")[0]
            ); // remove timestamp from datestring
            break;
          }
        }
      }
    } else { // if custom period is selected
      if (customDates.length < 2) { // if start and/or end dates are not selected yet
        return;
      }
      startDate = moment(
        `${customDates[0].day} ${customDates[0].month} ${customDates[0].year}`,
        "DD MMM YYYY"
      );
      endDate = moment(
        `${customDates[1].day} ${customDates[1].month} ${customDates[1].year}`,
        "DD MMM YYYY"
      ).endOf("day");

      for (let i in data) {
        let entry = data[i];
        entry.data = entry.data.filter((datestring) => {
          const date = moment(datestring);
          return date.isBetween(startDate, endDate);
        });
        entry.data = entry.data.map(
          (
            datestring // remove timestamp from datestring
          ) => datestring.split(" ")[0]
        );
      }
    }
    dateRange = generateDateRange(startDate, endDate); // determine all the dates within the date range
    
    // count the number of likes/views on each date within the date range
    data = generateRecords(data, dateRange);

    // generate datapoints for the chart
    let count = 1;
    let datapoints = [];
    for (let date in dateRange) {
      let datapoint = {date: count};
      for (let j in data) {
        let title = data[j].world_name + '_' + data[j].type;
        datapoint[title] = data[j].records[date];
      }
      datapoints.push(datapoint);
      count++;
    }
    setChartingData(datapoints);
    console.log(datapoints);
  }, [
    selectedLikesViews,
    chartData,
    customDates,
    selectedWorlds,
    selectedDate,
  ]);

  const CustomLegend = ({ payload }) => {
    return (
      <ul
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "2%",
        }}
      >
        {selectedWorlds.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              listStyleType: "none",
              display: "flex",
              alignItems: "center",
              marginBottom: "2%",
              marginLeft: "1%",
              marginRight: "1%",
              width: "calc(80% / 3)",
            }}
          >
            <svg width="20" height="10">
              <line
                x1="0"
                y1="5"
                x2="20"
                y2="5"
                stroke={worldColors[entry]}
                strokeWidth="2"
                strokeDasharray="none"
              />
            </svg>
            <span style={{ color: "black", marginLeft: "5px" }}>{entry}</span>
          </li>
        ))}
      </ul>
    );
  };

  let colorIndex = 0;

  const getNextColor = () => {
    const color = colors[colorIndex];
    colorIndex = (colorIndex + 1) % colors.length;
    return color;
  };

  const worldNames = Array.from(
    new Set(
      Object.keys(chartData[0] || {})
        .filter((key) => key !== "name")
        .map((key) => key.split(" ")[0])
    )
  );

  const worldColorMap = worldNames.reduce(
    (acc, world) => ({ ...acc, [world]: getNextColor() }),
    {}
  );

  const convertName = (name) => {
    return name.split("_")[0] + " " + name.split("_")[1];
  };

  const setColor = (name) => {
    let worldName = name.slice(0, -6);
    return worldColors[worldName];
  };

  const setDashedLine = (name) => {
    if (name.slice(-5) == "views") {
      return "5 5";
    }
  };

  const setLines = () => {
      return Object.keys(chartingData[0]).map((name, index) => {
      return name != 'date' && selectedWorlds.includes(name.slice(0, -6)) ? <Line type="monotone" key={name} dataKey={name} stroke={setColor(name)} name={convertName(name)} strokeDasharray={setDashedLine(name)}/> : null});
  };
  return (
    <div className="flex justify-between w-full">
      <div className=" p-5" style={{ width: "75%" }}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={chartingData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="6 6" />
            <XAxis
              dataKey="date"
            />
            <YAxis />
            <Tooltip
              // content={({ payload, label }) => {
              //   const viewsPayloads = payload?.filter((p) =>
              //     p.dataKey.includes("(Views)")
              //   );
              //   const date = new Date(label);
              //   const formattedLabel = date.toLocaleDateString("en-GB", {
              //     day: "2-digit",
              //     month: "2-digit",
              //     year: "numeric",
              //   });
              //   return (
              //     <div
              //       style={{
              //         backgroundColor: "white",
              //         padding: "10px",
              //         border: "1px solid black",
              //       }}
              //     >
              //       <p style={{ fontSize: 16 }}>{formattedLabel}</p>
              //       {viewsPayloads.map((p) => (
              //         <p key={p.dataKey} style={{ fontSize: 16 }}>
              //           {p.dataKey}: {p.value}
              //         </p>
              //       ))}
              //     </div>
              //   );
              // }}
            /> 
            {/* {chartingData.length > 0 && Object.keys(chartingData[0]).forEach((name) => {console.log(name)})} */}
            {chartingData.length > 0 && setLines()}
            {/* <Line type="monotone" dataKey="VTF metaverse_likes" stroke="#8884d8" name="Lite Likes" /> */}
            <Legend
              content={<CustomLegend />}
              layout="horizontal"
              align="left"
              verticalAlign="top"
            />
            {/* <Line type='linear' datakey="VTF metaverse_views" stroke='#FF595E' activeDot={{ r: 8 }}/> */}
            {/* {selectedWorlds.map((world, index) => {<>
            {selectedLikesViews.includes('Likes') && selectedLikesViews.includes('Views') ? (<><Line type='monotone' datakey={world + "_views"} stroke={colors[index]}/> <Line type='monotone' datakey={world + "_likes"} stroke={colors[index]}/></>): <></>}
            
            </>})} */}
            
          </LineChart>
        </ResponsiveContainer>
      </div>
      <SelectionPanel
        allWorlds={allWorlds}
        selectedWorlds={selectedWorlds}
        setSelectedWorlds={setSelectedWorlds}
        selectedLikesViews={selectedLikesViews}
        setSelectedLikesViews={setSelectedLikesViews}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setCustomDates={setCustomDates}
      />
    </div>
  );
};
export default ChartWindow;
